/* istanbul ignore file */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
    AccountServerResponse,
    BrandingIdentity,
    LogIn,
    LogInResponse,
    OrganizationAccount,
    ServerResponseBase,
    UserInfo,
} from '../utils/app.types';
import { envConfig } from '../utils/env.conf';
import { formatAccountsResponse } from '../utils/helpers.utils';

const baseUrl = `${envConfig.API_URL}`;

export const baseApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl }),
    tagTypes: ['BENEFICIARY', 'DONATION'],
    endpoints: (builder) => ({
        logIn: builder.mutation<LogInResponse, LogIn>({
            query: ({ email, password }) => ({
                url: 'Users/login',
                body: [email, password],
                credentials: 'include',
                method: 'POST',
            }),
        }),
        logOut: builder.mutation<void, void>({
            query: () => ({
                url: 'Users/logout',
                credentials: 'include',
                method: 'POST',
            }),
        }),
        getAccounts: builder.query<Array<OrganizationAccount>, void>({
            query: () => ({
                url: 'Users/Account',
                credentials: 'include',
                method: 'POST',
            }),
            transformResponse: (response: AccountServerResponse) =>
                formatAccountsResponse(response),
        }),
        getMe: builder.query<UserInfo, Array<number | undefined>>({
            query: (accountIds) => ({
                url: 'Users/me',
                body: [accountIds],
                credentials: 'include',
                method: 'POST',
            }),
            transformResponse: (response: ServerResponseBase<UserInfo>) => response.result?.data,
        }),
        getIdentity: builder.query<BrandingIdentity, Array<number | undefined>>({
            query: (accountIds) => ({
                url: 'Users/getIdentity',
                body: accountIds,
                credentials: 'include',
                method: 'POST',
            }),
            transformResponse: (response: ServerResponseBase<BrandingIdentity>) =>
                response.result?.data,
        }),
    }),
});

export const {
    useGetMeQuery,
    useLazyGetMeQuery,
    useLogInMutation,
    useGetIdentityQuery,
    useGetAccountsQuery,
    useLazyGetAccountsQuery,
    useLogOutMutation,
} = baseApi;
