import i18n from '../i18n';
import { TranslationKeys, ValueOf } from './app.types';

export enum USER_ROLES {
    ADMIN = 'Admin',
    OPERATIONNEL = 'Opérationnel',
    COMPTABILITE = 'Comptabilité',
    LOGISTIQUE = 'Logistique',
    NONE = '',
}

export const PAGES = {
    home: '/',
    login: `/${i18n.t('IDS_NAVIGATION_URL_login')}`,
    dashboard: `/${i18n.t('IDS_NAVIGATION_URL_dashboard')}`,
    donations: `/${i18n.t('IDS_NAVIGATION_URL_donations')}`,
    equipment: `/${i18n.t('IDS_NAVIGATION_URL_equipments')}`,
    beneficiaries: `/${i18n.t('IDS_NAVIGATION_URL_beneficiaries')}`,
    parameters: `/${i18n.t('IDS_NAVIGATION_URL_settings')}`,
    mentions_legales: '/mentions-legales',
    politique_confidentialite: '/politique-confidentialite',
    cookies_declaration: '/cookies-declaration',
    need_help_m: '/need-help-m',
    maintains: '/maintains',
};

export enum GDPR_PAGES {
    MENTIONS = 'mentions_legales.html',
    POLITICAL = 'politique_confidentialite.html',
}

export const ExceptNavPages = new Map<string, string>();

ExceptNavPages.set(PAGES.login, '');
ExceptNavPages.set(PAGES.cookies_declaration, '');
ExceptNavPages.set(PAGES.mentions_legales, '');
ExceptNavPages.set(PAGES.politique_confidentialite, '');
ExceptNavPages.set(PAGES.maintains, '');

export const ATF_GAIA_URL = 'https://www.atf-gaia.fr/fr';
export const ACCOUNT_STORE_KEY = 'selected-account';

export const DONATION_STATUSES = {
    draft: 'Draft',
    waiting_signature: 'En_attente_de_signature',
    in_progress: 'En_cours',
    sent: 'Expédiee',
    finished: 'Terminee',
    cancelled: 'Annulee',
} as const;

export const DONATION_STATUSES_TRANSLATION_KEYS: Record<
    ValueOf<typeof DONATION_STATUSES>,
    Partial<TranslationKeys>
> = {
    Draft: 'IDS_DONATION_STATUS_draft',
    En_attente_de_signature: 'IDS_DONATION_STATUS_waiting-signature',
    En_cours: 'IDS_DONATION_STATUS_in-progress',
    Expédiee: 'IDS_DONATION_STATUS_sent',
    Terminee: 'IDS_DONATION_STATUS_finished',
    Annulee: 'IDS_DONATIONS_STATUS_cancelled',
};

export enum PRODUCT_FAMILY {
    uniteCentrales = 'UNITES CENTRALES',
    portables = 'PORTABLES',
    mobility = 'MOBILITE',
    allInOne = 'ALL IN ONE',
    ecransBureau = 'ECRANS BUREAU',
    workStation = 'WORKSTATION',
    tabletPc = 'TABLETTES PC',
}

export const OPTION_VARIANTS: Record<string, Partial<TranslationKeys>> = {
    'Open Office': 'IDS_EQUIPMENT_OPTIONS_open-office-install',
    'Conditionnment Unitaire': 'IDS_EQUIPMENT_OPTIONS_unit-package',
    Sacoche: 'IDS_EQUIPMENT_OPTIONS_bag',
    Clavier: 'IDS_EQUIPMENT_OPTIONS_keyboard',
    Souris: 'IDS_EQUIPMENT_OPTIONS_mouse',
    'Windows citizen': 'IDS_EQUIPMENT_OPTIONS_system-install',
};

export const EURO = '€';

export const FAMILY_KEYS: Record<string, Partial<TranslationKeys>> = {
    'UNITES CENTRALES': 'IDS_EQUIPMENT_TYPE_desktop',
    'ALL IN ONE': 'IDS_EQUIPMENT_TYPE_all-in-one',
    'ECRANS BUREAU': 'IDS_EQUIPMENT_TYPE_monitors',
    MOBILITE: 'IDS_EQUIPMENT_TYPE_tablet-smartphone',
    PORTABLES: 'IDS_EQUIPMENT_TYPE_laptop',
    'TABLETTES PC': 'IDS_EQUIPMENT_TYPE_tablet-pc',
    WORKSTATION: 'IDS_EQUIPMENT_TYPE_workstation',
    ALL: 'IDS_GLOBAL_all',
};
