import { FC, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/ico-delete.svg';
import { useCheckOverflow } from '../../../hooks/use-check-overflow.hook';
import { useDeviceScreenDetect } from '../../../hooks/use-device-screen-detect.hook';
import { changeSelectedItemQuantity, deselectEquipment } from '../../../store/slice/create-donation.slice';
import { SelectedEquipment } from '../../../utils/app.types';
import { CounterButton } from '../../counter-btn/counter-btn';
import { ProductIcon } from '../../product-card/product-icon/product-icon';
import { ToolTip } from '../../tooltip/tooltip';
import './sidebar-equipment-card.scss';
import { StockError } from './stock-error';

type SidebarEquipmentCardProps = {
    item: SelectedEquipment;
    isReadOnly?: boolean;
};

export const SidebarEquipmentCard: FC<SidebarEquipmentCardProps> = ({ item, isReadOnly = true }) => {
    const titleRef = useRef<HTMLDivElement>(null);
    const withToolTip = useCheckOverflow(titleRef);
    const dispatch = useDispatch();
    const { isPortraitTablet, isMobile } = useDeviceScreenDetect();
    const onQuantityChange = useCallback((value: number) => {
        dispatch(changeSelectedItemQuantity({ id: item.id, quantity: value }));
    }, []);

    const removeEquipmentSelection = useCallback(() => {
        dispatch(deselectEquipment(item.id));
    }, []);

    const isCountValid = item.selectedCount <= item.maximumCount && item.selectedCount !== 0;

    if (isPortraitTablet || isMobile) {
        return (
            <div className='sidebar_equipment_card'>
                <div className='card_equipment_data'>
                    <div className='card_information_container'>
                        <div className='icon'>
                            <ProductIcon productType={item.family} />
                        </div>
                        <div className='mobile_counter_card_info'>
                            <div className='text_container mobile'>
                                <ToolTip content={item.name} isActive={withToolTip}>
                                    <div ref={titleRef} className='equipment_name'>
                                        {item.name}
                                    </div>
                                </ToolTip>
                                <div className='equipment_grade'>{item.grade}</div>
                            </div>
                            <CounterButton
                                isReadOnly={isReadOnly}
                                className='sidebar_card_counter'
                                maxVal={item.maximumCount}
                                value={item.selectedCount}
                                onChange={onQuantityChange}
                            />
                        </div>
                    </div>
                    {!isReadOnly && (
                        <div className='card_actions_container'>
                            <div className='delete_icon' onClick={removeEquipmentSelection}>
                                <DeleteIcon />
                            </div>
                        </div>
                    )}
                </div>
                {!isReadOnly && <StockError showError={item.stockError || !isCountValid} />}
            </div>
        );
    }

    return (
        <div className='sidebar_equipment_card'>
            <div className='card_equipment_data'>
                <div className='card_information_container'>
                    <div className='icon'>
                        <ProductIcon productType={item.family} />
                    </div>
                    <div className='text_container'>
                        <ToolTip content={item.name} isActive={withToolTip}>
                            <div ref={titleRef} className='equipment_name'>
                                {item.name}
                            </div>
                        </ToolTip>

                        <div className='equipment_grade'>{item.grade}</div>
                    </div>
                </div>

                <div className='card_actions_container'>
                    <CounterButton
                        isReadOnly={isReadOnly}
                        maxVal={item.maximumCount}
                        value={item.selectedCount}
                        onChange={onQuantityChange}
                    />

                    {!isReadOnly && (
                        <div className='delete_icon' onClick={removeEquipmentSelection}>
                            <DeleteIcon />
                        </div>
                    )}
                </div>
            </div>

            {!isReadOnly && <StockError showError={item.stockError || !isCountValid} />}
        </div>
    );
};
