import { FC } from 'react';

import { ReactComponent as AllInOne } from '../../../assets/icons/products/ico-allineone.svg';
import { ReactComponent as Laptop } from '../../../assets/icons/products/ico-laptop.svg';
import { ReactComponent as Monitor } from '../../../assets/icons/products/ico-monitor.svg';
import { ReactComponent as UC } from '../../../assets/icons/products/ico-new-uc.svg';
import { ReactComponent as TabletPC } from '../../../assets/icons/products/ico-tabletPC.svg';
import { ReactComponent as TableteSmart } from '../../../assets/icons/products/ico-tablette-smart.svg';
import { ReactComponent as WorkStation } from '../../../assets/icons/products/ico-workstation.svg';
import { PRODUCT_FAMILY } from '../../../utils/app.enums';

type ProductIconProps = {
    productType: string;
};
export const ProductIcon: FC<ProductIconProps> = ({ productType }) => {
    switch (productType) {
        case PRODUCT_FAMILY.uniteCentrales:
            return <UC />;
        case PRODUCT_FAMILY.allInOne:
            return <AllInOne />;
        case PRODUCT_FAMILY.portables:
            return <Laptop />;
        case PRODUCT_FAMILY.mobility:
            return <TableteSmart />;
        case PRODUCT_FAMILY.ecransBureau:
            return <Monitor />;
        case PRODUCT_FAMILY.tabletPc:
            return <TabletPC />;
        case PRODUCT_FAMILY.workStation:
            return <WorkStation />;

        default:
            return null;
    }
};
