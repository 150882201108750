import { RefObject, useEffect } from 'react';

import { Func } from '../utils/app.types';

export const useOutsideClick = <T extends HTMLElement>(ref: RefObject<T>, onClick: Func) => {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                onClick();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};
