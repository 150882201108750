import cn from 'classnames';
import { FC, useMemo } from 'react';

import { useTypedTranslation } from '../../../hooks/use-typed-translation';
import { EURO, OPTION_VARIANTS } from '../../../utils/app.enums';
import { DonationOption, Func } from '../../../utils/app.types';

type OptionProps = {
    option: DonationOption;
    isOptionSelected: boolean;
    onClick: Func<DonationOption>;
};
export const Option: FC<OptionProps> = ({ option, isOptionSelected, onClick }) => {
    const { t } = useTypedTranslation();

    const isFreeOption = useMemo(() => option.name.includes('-FREE'), [option]);
    const isSelected = isOptionSelected || isFreeOption;

    const optionName = useMemo(() => {
        if (isFreeOption) {
            const name = option.name.replace('-FREE', '');
            const [translatedName] = t(OPTION_VARIANTS[name], { count: 0 }).split(':');
            return translatedName;
        }
        return t(OPTION_VARIANTS[option.name], { count: option.unitPrice });
    }, [option, isFreeOption]);

    return (
        <>
            <div key={option.name} className='option'>
                <div className='option_name'>{optionName}</div>
                <div className='option_action'>
                    <button
                        className={cn('add_option', {
                            active: isSelected,
                            free: isFreeOption,
                        })}
                        onClick={() => onClick(option)}>
                        {isSelected ? (
                            <>
                                <div className='price'>
                                    {isFreeOption ? t('IDS_DONATIONS_PRICE_free') : `${option.total} ${EURO}`}
                                </div>

                                {!isFreeOption && (
                                    <div>{t('IDS_DONATIONS_OPTIONS_CTA_added-price-option')}</div>
                                )}
                            </>
                        ) : (
                            <>
                                <div>{t('IDS_DONATIONS_OPTIONS_CTA_add-price-option')}</div>
                                <div className='price'>
                                    +{option.total}
                                    {EURO}
                                </div>
                            </>
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};
