import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useDeviceScreenDetect } from '../../hooks/use-device-screen-detect.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { useLazyGetAccountsQuery, useLazyGetMeQuery, useLogInMutation } from '../../services/base.service';
import { setAccount } from '../../store/slice/account.slice';
import { ACCOUNT_STORE_KEY, PAGES } from '../../utils/app.enums';
import { envConfig } from '../../utils/env.conf';
import { getFirstAlphabeticalAccount } from '../../utils/helpers.utils';
import { validateLogIn } from '../../utils/validations';
import { Button } from '../button/button';
import { Input } from '../input/input';
import './log-in-form.scss';

const linkToResetPass = `${envConfig.MYATF_URL}/forgot-password`;

export const LogInForm = () => {
    const [showErrorForEmail, setShowErrorForEmail] = useState(false);
    const [showErrorForPassword, setShowErrorForPassword] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const emailRef = useRef<HTMLInputElement>(null);

    const [logInUser, { error }] = useLogInMutation();
    const [getAccounts] = useLazyGetAccountsQuery();
    const [getUser] = useLazyGetMeQuery();

    const { isMobile } = useDeviceScreenDetect();
    const { t } = useTypedTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validate: validateLogIn({
            emailError: t('IDS_ERROR_FIELD_email-address-not-valid'),
            passwordError: t('IDS_ERROR_FIELD_password-not-valid'),
        }),
        onSubmit: async (logInData) => {
            const logInResponse = await logInUser(logInData);

            if ('data' in logInResponse && logInResponse.data.result.success) {
                const { data: accountsResponse } = await getAccounts(undefined);
                if (accountsResponse) {
                    const firstAccount = getFirstAlphabeticalAccount(accountsResponse);
                    await getUser([firstAccount.id]);
                    localStorage.setItem(ACCOUNT_STORE_KEY, JSON.stringify(firstAccount));
                    dispatch(setAccount(firstAccount));
                    navigate(PAGES.dashboard);
                }
            }
        },
    });

    const validate = useCallback(
        (form: 'pass' | 'email') => () => {
            form === 'email' ? setShowErrorForEmail(true) : setShowErrorForPassword(true);
        },
        []
    );

    useEffect(() => {
        if (formik.values.email && formik.values.password) {
            setIsFormFilled(true);
        }
    }, [formik.values]);

    useEffect(() => {
        setTimeout(() => {
            if (emailRef.current && emailRef.current.matches(':-webkit-autofill')) {
                formik.isValid = true;
                setIsFormFilled(true);
            }
        }, 500);
    }, []);

    return (
        <div className='login_form'>
            <div className='form_title'>{t('IDS_ACCESS_LOGIN_BUTTON_access-your-account')}</div>
            {!isMobile && (
                <div data-testid='form_subtitle' className={'form_subtitle'}>
                    {t('IDS_ACCESS_LOGIN_DESCRIPTION_use-myatf-credentials')}
                </div>
            )}

            <form className='form_container' onSubmit={formik.handleSubmit}>
                {error && <div className='server_error'>{t('IDS_ERROR_LOGIN_invalid-credentials')}</div>}

                <Input
                    inputRef={emailRef}
                    id={'email'}
                    name={'email'}
                    type={'text'}
                    onChange={formik.handleChange}
                    onBlur={validate('email')}
                    value={formik.values.email}
                    label={t('IDS_GLOBAL_your-email-address')}
                    error={formik.errors.email}
                    showValidation={showErrorForEmail}
                />
                <Input
                    id={'password'}
                    name={'password'}
                    type={'password'}
                    onChange={formik.handleChange}
                    onBlur={validate('pass')}
                    value={formik.values.password}
                    label={t('IDS_GLOBAL_password')}
                    error={formik.errors.password}
                    showValidation={showErrorForPassword}
                />
                <a className='form_reset_pass_link' href={linkToResetPass}>
                    {t('IDS_ACCESS_PASSWORD_forgotten-password')}
                </a>

                <div className='form_login_btn'>
                    <Button
                        type='primary'
                        onClick={formik.submitForm}
                        isDisabled={!(isFormFilled && formik.isValid)}
                        isLoading={formik.isSubmitting}>
                        {t('IDS_ACCESS_LOGIN_BUTTON_access-your-account')}
                    </Button>
                </div>
            </form>
        </div>
    );
};
