/* istanbul ignore file */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Client,
    CreateDonationRequest,
    DonationInformation,
    DonationOption,
    DonationPaginationRequest,
    DonationPaginationResponse,
    EquipmentPaginationResponse,
    GetDonationOptionsRequest,
    GetDonationRequest,
    PaginationRequestProducts,
    ServerResponseBase,
    ValidateDonationResponse,
} from '../utils/app.types';
import { getClientInnerValue } from '../utils/helpers.utils';
import { baseApi } from './base.service';

export const donationApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getEquipments: builder.query<EquipmentPaginationResponse, PaginationRequestProducts>({
            query: (productsPage) => ({
                url: 'Donation/products',
                body: [productsPage],
                credentials: 'include',
                method: 'POST',
            }),
            providesTags: ['DONATION'],
            transformResponse: (resp: any) => resp.result,
        }),
        getDonationList: builder.query<DonationPaginationResponse, DonationPaginationRequest>({
            query: (productsPage) => ({
                url: 'Donation/list',
                body: [productsPage],
                credentials: 'include',
                method: 'POST',
            }),
            providesTags: ['DONATION'],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            transformResponse: (resp: any) => resp.result,
        }),
        getDonationOptions: builder.query<DonationOption[], GetDonationOptionsRequest>({
            query: (productsPage) => ({
                url: 'Donation/options',
                body: [productsPage],
                credentials: 'include',
                method: 'POST',
            }),
            providesTags: ['DONATION'],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            transformResponse: (resp: any) => resp.result?.data?.list,
        }),
        getSupplierClient: builder.query<Client[], number>({
            query: (supplierId) => ({
                url: 'Statistiques/DOETHAccount',
                body: [[supplierId, 1]],
                credentials: 'include',
                method: 'POST',
            }),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            transformResponse: (resp: any) => getClientInnerValue(resp.result.Clients),
        }),
        createDonation: builder.mutation<ValidateDonationResponse, CreateDonationRequest>({
            query: (productsPage) => ({
                url: 'Donation/create',
                body: [productsPage],
                credentials: 'include',
                method: 'POST',
            }),
            invalidatesTags: ['DONATION'],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            transformResponse: (resp: any) => resp.result,
        }),
        saveDraftDonation: builder.mutation<ServerResponseBase<unknown>, CreateDonationRequest>({
            query: (productsPage) => ({
                url: 'Donation/createdraft',
                body: [productsPage],
                credentials: 'include',
                method: 'POST',
            }),
            invalidatesTags: ['DONATION'],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            transformResponse: (resp: any) => resp.result,
        }),
        updateDraftDonation: builder.mutation<ServerResponseBase<unknown>, CreateDonationRequest>({
            query: (productsPage) => ({
                url: 'Donation/updatedraft',
                body: [productsPage],
                credentials: 'include',
                method: 'POST',
            }),
            invalidatesTags: ['DONATION'],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            transformResponse: (resp: any) => resp.result,
        }),
        getDonation: builder.query<DonationInformation, GetDonationRequest>({
            query: (donationInfo) => ({
                url: 'Donation/getDonation',
                body: [donationInfo],
                credentials: 'include',
                method: 'POST',
            }),
            providesTags: ['DONATION'],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            transformResponse: (resp: any) => resp.result.data,
        }),
        getPrice: builder.query<number, number>({
            query: (supplier) => ({
                url: 'Donation/getPrice',
                body: [{ supplier }],
                credentials: 'include',
                method: 'POST',
            }),
            providesTags: ['DONATION'],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            transformResponse: (resp: any) => resp?.result?.price ?? 0,
        }),
    }),
});

export const {
    useGetEquipmentsQuery,
    useGetDonationListQuery,
    useGetDonationOptionsQuery,
    useGetSupplierClientQuery,
    useCreateDonationMutation,
    useSaveDraftDonationMutation,
    useGetDonationQuery,
    useLazyGetDonationQuery,
    useUpdateDraftDonationMutation,
    useLazyGetSupplierClientQuery,
    useGetPriceQuery,
} = donationApi;
