import { CreateDonationForm } from './app.types';

type FormikValidationErrors = {
    email?: string;
    password?: string;
};
export const validateLogIn =
    ({ emailError, passwordError }: Record<string, string>) =>
    ({ email, password }: Record<string, string>) => {
        const errors: FormikValidationErrors = {};

        if (!email || !isValidEmail(email)) {
            errors.email = emailError;
        }

        if (!password || !isValidPasswordPattern(password)) {
            errors.password = passwordError;
        }

        return errors;
    };

const isValidPasswordPattern = (pass: string): boolean => {
    return pass.length >= 6 && pass.length < 40;
};

const isValidEmail = (email: string): boolean => {
    if (!email) {
        return false;
    }

    const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*.(com|fr|net)$/;

    return re.test(String(email).toLowerCase());
};

type CreateDonationFormErrors = Partial<{ [key in keyof CreateDonationForm]: string }>;

export const createDonationValidation = (errors: CreateDonationFormErrors) => {
    return (values: CreateDonationForm) => {
        const errorObject: CreateDonationFormErrors = {};
        if (!values.beneficiary) {
            errorObject.beneficiary = errors.beneficiary;
        }
        if (!values.donationName) {
            errorObject.donationName = errors.donationName;
        }
        if (!values.equipmentsList) {
            errorObject.equipmentsList = errors.equipmentsList;
        }

        return errorObject;
    };
};
