import { ReactComponent as AtfLogo } from '../../assets/icons/atf-logo.svg';
import { LegalLinks } from '../../components/legal-links/legal-links';
import { LogInForm } from '../../components/log-in-form/log-in-form';
import { useDeviceScreenDetect } from '../../hooks/use-device-screen-detect.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import './login.page.scss';

export const LogInPage = () => {
    const sizes = useDeviceScreenDetect();
    const { t } = useTypedTranslation();

    return (
        <div className='login_page'>
            <div className='outside_content'>
                <div className='additional_info_container'>
                    <div className='hello_text'>
                        {t('IDS_GLOBAL_MARKETING_give-equipments-to-charities')}
                    </div>
                    {(sizes.isDesktop || sizes.isLandscapeTablet) && (
                        <LegalLinks className='main_page_legal_links' />
                    )}
                </div>
            </div>
            <div className='logo'>
                <AtfLogo />
            </div>
            <div className='form'>
                <LogInForm />
            </div>
            {(sizes.isPortraitTablet || sizes.isMobile) && (
                <div className='legal_links_container'>
                    <LegalLinks />
                </div>
            )}
        </div>
    );
};
