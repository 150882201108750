import { ChangeEvent, FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useTypedTranslation } from '../../../hooks/use-typed-translation';
import { selectDonationNameValidation } from '../../../store/selectors';
import { setDonationName, setFormValidation } from '../../../store/slice/create-donation.slice';
import { useTypedSelector } from '../../../store/store';
import { Input } from '../../input/input';

type DonationNameInputProps = {
    donationName: string;
};
export const DonationNameInput: FC<DonationNameInputProps> = ({ donationName }) => {
    const dispatch = useDispatch();
    const { t } = useTypedTranslation();
    const { showNameError, isNameValid } = useTypedSelector(selectDonationNameValidation);
    const onDonationNameInput = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
        dispatch(setDonationName(target.value));
    }, []);

    const validateDonationName = (name: string) => {
        if (!name) {
            dispatch(setFormValidation({ showNameError: true }));
        }
    };

    return (
        <div className='donation_name_input'>
            <Input
                id='donationName'
                name={'donationName'}
                value={donationName}
                label={t('IDS_DONATION_donation-name')}
                onChange={onDonationNameInput}
                onBlur={() => validateDonationName(donationName)}
                showValidation={showNameError}
                error={!isNameValid && t('IDS_GLOBAL_ERROR_required-field')}
                type='text'
            />
        </div>
    );
};
