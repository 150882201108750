import { useEffect, useState } from 'react';

import logo from '../assets/icons/atf-logo.svg';
import banner from '../assets/images/log-in.jpeg';
import { useGetIdentityQuery } from '../services/base.service';
import { selectSupplierId } from '../store/selectors';
import { useTypedSelector } from '../store/store';
import { BrandingIdentity } from '../utils/app.types';
import { getRGB, getTextColor } from '../utils/helpers.utils';

export const initialUserIdentity: BrandingIdentity = {
    logo: logo,
    banner: banner,
    primary: 'rgb(127, 166, 0)',
    secondary: 'rgb(0, 125, 143)',
};

export const useGetAccountThemes = () => {
    const id = useTypedSelector(selectSupplierId);
    const [isErrorInRequest, setIsErr] = useState(false);
    const {
        data: themes = initialUserIdentity,
        isFetching,
        isError,
        error,
    } = useGetIdentityQuery([id], {
        skip: !id || isErrorInRequest,
        refetchOnReconnect: true,
    });

    useEffect(() => {
        if (themes) {
            const doc = document.documentElement;
            const primaryColor = getRGB(themes.primary);
            const secondaryColor = getRGB(themes.secondary);

            const fontColorPrimary = getTextColor(primaryColor);
            const fontColorSecondary = getTextColor(secondaryColor);

            doc.style.setProperty(
                '--primary-color',
                `${primaryColor.R}, ${primaryColor.G}, ${primaryColor.B}`
            );
            doc.style.setProperty(
                '--secondary-color',
                `${secondaryColor.R}, ${secondaryColor.G}, ${secondaryColor.B}`
            );

            doc.style.setProperty('--primary-color-text', fontColorPrimary);
            doc.style.setProperty('--secondary-color-text', fontColorSecondary);
        }
    }, [themes]);

    useEffect(() => {
        setIsErr(false);
    }, [id]);

    useEffect(() => {
        if (isError) {
            setIsErr(isError);
        }
    }, [isError]);

    return { themes, isLoading: isFetching, isError, error };
};
