import { useGetUserInformation } from '../../hooks/use-get-me.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import './need-help.scss';

export const NeedHelpModalContent = () => {
    const { userData } = useGetUserInformation();
    const { t } = useTypedTranslation();

    return (
        <div className='need_help_modal_content'>
            <div className='title'>
                {t('IDS_GLOBAL_need-help')}
                <div className={'underline'}></div>
            </div>

            <div className='sub_title'>{t('IDS_HELP_MODAL_SUBTITLE_need-help')}</div>

            <div className='help_description'>
                {t('IDS_HELP_MODAL_DESCRIPTION_help-description-examples')}
            </div>

            <div className='contact_container'>
                <div>
                    {t('IDS_HELP_MODAL_CONTACT_by-phone')}
                    <a href={`tel:${userData?.support.phone}`} className={'phone'}>
                        {userData?.support.phone}
                    </a>
                </div>
                <div>
                    {t('IDS_HELP_MODAL_CONTACT_by-email')}{' '}
                    <a href={`mailto:${userData?.support.email}`} className='email'>
                        e-mail
                    </a>
                </div>
            </div>
        </div>
    );
};
