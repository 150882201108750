import { initialUserIdentity } from '../hooks/use-get-account-themes.hook';
import {
    Account,
    AccountServerResponse,
    Client,
    CreateDonationForm,
    CreateDonationRequest,
    CreateDonationRequestProduct,
    OrganizationAccount,
    RGB,
    SelectedEquipment,
    StrRecord,
} from './app.types';

export const getAccountIds = (organizationAccounts: Array<OrganizationAccount>) => {
    const subAccounts = organizationAccounts.reduce(
        (prevVal, currentVal) => prevVal.concat(currentVal.subAccounts.map((subAccount) => subAccount.id)),
        new Array<number>()
    );

    return subAccounts;
};

export const getFirstAlphabeticalAccount = (organizationAccounts: Array<OrganizationAccount>): Account => {
    let subAccounts = organizationAccounts.reduce(
        (prevVal, currentVal) => prevVal.concat(currentVal.subAccounts),
        new Array<Account>()
    );

    subAccounts = subAccounts.sort((accountA, accountB) =>
        accountA.name?.toLocaleLowerCase() < accountB.name?.toLocaleLowerCase() ? -1 : 1
    );

    return subAccounts[0];
};

export const formatAccountsResponse = (accountResponse: AccountServerResponse) => {
    const groupes = accountResponse.result.groupes;

    const formattedData: Array<OrganizationAccount> = groupes?.map((parentAccount) => ({
        name: Object.keys(parentAccount)[0],
        subAccounts: Object.values(parentAccount)[0].map((subAccount) => ({
            id: Object.values(subAccount)[0],
            name: Object.keys(subAccount)[0],
        })),
    }));

    return formattedData;
};

// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
export const emptyFn = () => {};

export const getRGB = (color: string) => {
    const [R, G, B] = color.split(',').map((x) => parseFloat(x.replace(/\D/g, '')));

    return {
        R,
        G,
        B,
    };
};

export const getRGBAStringColor = (color: string, opacity = 1) => {
    const { R, G, B } = getRGB(color);

    return `rgba(${R}, ${G}, ${B}, ${opacity})`;
};

const componentToHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
};

export function rgbToHex({ R, G, B }: RGB) {
    return '#' + componentToHex(R) + componentToHex(G) + componentToHex(B);
}

export function detectRealScreenSize() {
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
}

export function resetThemeColors() {
    const doc = document.documentElement;
    const primaryColor = getRGB(initialUserIdentity.primary);
    const secondaryColor = getRGB(initialUserIdentity.secondary);

    doc.style.setProperty('--primary-color', `${primaryColor.R}, ${primaryColor.G}, ${primaryColor.B}`);
    doc.style.setProperty(
        '--secondary-color',
        `${secondaryColor.R}, ${secondaryColor.G}, ${secondaryColor.B}`
    );
    doc.style.setProperty('--primary-color-text', '255,255,255,1');
    doc.style.setProperty('--secondary-color-text', '255,255,255,1');
}

export function mapFamily(equipments: SelectedEquipment[] = []) {
    return equipments
        .filter((item) => item.maximumCount > 0)
        .map((item) => ({
            family: item.family,
            qty: item.selectedCount > item.maximumCount ? item.maximumCount : item.selectedCount,
        }));
}

export const formatCreateDonationForm = (form: CreateDonationForm, supplier: number, customerId?: number) => {
    const { donationName, beneficiary, equipmentsList, options, donationId } = form;

    if (donationName && beneficiary && equipmentsList) {
        const requestObject: CreateDonationRequest = {
            address: beneficiary.adresse,
            beneficiaryId: beneficiary.beneficiaryId,
            beneficiaryName: beneficiary.nameBeneficiary,
            city: beneficiary.city,
            country: beneficiary.country,
            zip_code: beneficiary.zip_code,
            contact: {
                email: beneficiary.mail,
                firstname: beneficiary.firstname,
                name: beneficiary.name,
                phone: beneficiary.phone,
            },
            options:
                options?.map((option) => ({
                    name: option.name,
                    unitPrice: option.unitPrice,
                    total: option.total,
                })) ?? [],
            products: equipmentsList.map((equipment) => ({
                description: equipment.description,
                productId: equipment.id,
                qty: equipment.selectedCount,
            })),
            donationName,
            supplier,
            customerId,
            draftId: donationId,
        };

        return requestObject;
    }

    return null;
};

export const getClientInnerValue = (data: Array<StrRecord<Array<StrRecord<number>>>>) => {
    const itemRecord: Array<Client> = [];
    const clientsItem = data[0];
    const clientsArray = Object.values(clientsItem);

    const mappedClientsArray = clientsArray[0].reduce((record, client) => {
        const [name, id] = Object.entries(client)[0];
        record.push({ name, id });
        return record;
    }, itemRecord);

    return mappedClientsArray;
};

export function getTextColor({ R, G, B }: RGB) {
    const colorLum = 1 - (0.299 * R + 0.587 * G + 0.114 * B) / 255;

    if (colorLum < 0.5) {
        return '0,0,0,1';
    }
    return '255,255,255,1';
}

export const getPageCount = (totalItems?: number, perPage?: number) =>
    Math.ceil((totalItems ?? 1) / (perPage ?? 1));

export const formatBackEndProducts = (products: CreateDonationRequestProduct[]) => {
    return products.map((product) => ({
        id: product.productId,
        description: product.description,
        selectedCount: product.qty,
        maximumCount: 5,
    }));
};
//adjust API
//options - null,
//equipments-count = null,
// beneficiary ?
