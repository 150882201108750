import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../../assets/icons/ico-close.svg';
import { useTypedTranslation } from '../../../hooks/use-typed-translation';
import { PAGES } from '../../../utils/app.enums';
import { Func } from '../../../utils/app.types';
import { Button } from '../../button/button';
import { SideBar } from '../../side-bar/side-bar';
import './validation-sidebar.scss';

type ValidationSideBarProps = {
    open: boolean;
    close: Func;
    donationName: string;
};
export const ValidationSideBar: FC<ValidationSideBarProps> = ({ open, close, donationName }) => {
    const { t } = useTypedTranslation();
    const navigate = useNavigate();
    const gotDoDashboard = () => {
        close();
        navigate(PAGES.dashboard);
    };

    const goToDonations = () => {
        close();
        navigate(PAGES.donations);
    };
    return (
        <SideBar open={open} onOutsideClick={close}>
            <div className='create_donation_sidebar validation_sidebar'>
                <div className='close_icon'>
                    <div className='icon_container' onClick={() => close()}>
                        <CloseIcon />
                    </div>
                </div>

                <div className='donation_sidebar_title'>{donationName}</div>

                <div className='message'>
                    {t('IDS_DONATIONS_VALIDATION_DESCRIPTION_your-donation-taken-into-account')}
                </div>
                <div className='actions'>
                    <Button type='primary' className='btn' onClick={goToDonations}>
                        {t('IDS_DONATIONS_VALIDATION_CTA_see-my-donations')}
                    </Button>
                    <Button type='ghost' className='btn dashboard' onClick={gotDoDashboard}>
                        {t('IDS_NAVIGATION_URL_dashboard')}
                    </Button>
                </div>
            </div>
        </SideBar>
    );
};
