import { useEffect, useState } from 'react';

export const useDeviceScreenDetect = () => {
    const [sizes, setSizes] = useState({
        isMobile: false,
        isPortraitTablet: false,
        isLandscapeTablet: false,
        isDesktop: false,
    });

    useEffect(() => {
        const onResizeEvent = () => {
            const width = window.visualViewport?.width || 0;

            if (width >= 1200 && !sizes.isDesktop) {
                setSizes({
                    isDesktop: true,
                    isMobile: false,
                    isPortraitTablet: false,
                    isLandscapeTablet: false,
                });
            } else if (width >= 992 && width < 1200 && !sizes.isLandscapeTablet) {
                setSizes({
                    isDesktop: false,
                    isMobile: false,
                    isPortraitTablet: false,
                    isLandscapeTablet: true,
                });
            } else if (width >= 768 && width < 992 && !sizes.isPortraitTablet) {
                setSizes({
                    isDesktop: false,
                    isMobile: false,
                    isPortraitTablet: true,
                    isLandscapeTablet: false,
                });
            } else if (width < 768 && !sizes.isMobile) {
                setSizes({
                    isDesktop: false,
                    isMobile: true,
                    isPortraitTablet: false,
                    isLandscapeTablet: false,
                });
            }
        };

        onResizeEvent();
        window.addEventListener('resize', onResizeEvent);

        return () => window.removeEventListener('resize', onResizeEvent);
    }, [sizes]);

    return { ...sizes };
};
