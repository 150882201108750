import { useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseIcon } from '../../assets/icons/ico-close.svg';
import { emptyFn } from '../../utils/helpers.utils';
import './app-modal.scss';

export type ModalProps = {
    id: string;
    isOpen: boolean;
    onClose?: () => void;
    children: JSX.Element;
};

const Modal = ({ id, children, isOpen, onClose = emptyFn }: ModalProps) => {
    const onOutsideClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = event.target as HTMLDivElement;

        if (target.id === id) {
            onClose();
        }
    }, []);

    return (
        <>
            {isOpen && (
                <div id={id} className='app-modal' onClick={onOutsideClick}>
                    <div className='content_container'>
                        <div className='close_button' onClick={onClose}>
                            <CloseIcon />
                        </div>
                        <div className='content'>{children}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export const AppModal = (props: ModalProps) => {
    const { current: modalContainer } = useRef<HTMLDivElement>(document.querySelector(props.id));

    return !modalContainer
        ? ReactDOM.createPortal(<Modal {...props} />, document.querySelector('body') as HTMLElement)
        : null;
};
