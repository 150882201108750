import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCheckOverflow } from '../../hooks/use-check-overflow.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { selectEquipmentById } from '../../store/selectors';
import { deselectEquipment, setEquipment } from '../../store/slice/create-donation.slice';
import { showSnackBar } from '../../store/slice/snack-bar.slice';
import { useTypedSelector } from '../../store/store';
import { FAMILY_KEYS } from '../../utils/app.enums';
import { EquipmentProduct } from '../../utils/app.types';
import { CounterButton } from '../counter-btn/counter-btn';
import { ToolTip } from '../tooltip/tooltip';
import { ProductCardButton } from './product-card-button/product-card-button';
import './product-card.scss';
import { ProductIcon } from './product-icon/product-icon';

type ProductCardProp = {
    item: EquipmentProduct;
};

type BtnTypes = 'add' | 'added' | 'update';
export const ProductCard: FC<ProductCardProp> = ({ item }: ProductCardProp) => {
    const dispatch = useDispatch();
    const selectedEquipment = useTypedSelector((state) => selectEquipmentById(state, item.productId));
    const [count, setCount] = useState(0);
    const [btnType, setBtnType] = useState<BtnTypes>('add');
    const { t } = useTypedTranslation();

    const titleRef = useRef<HTMLDivElement>(null);
    const withToolTip = useCheckOverflow(titleRef);

    const selectItems = () => {
        dispatch(
            setEquipment({
                name: item.description,
                description: item.description,
                family: item.family,
                id: item.productId,
                maximumCount: item.qty,
                selectedCount: count,
                grade: item.grade,
            })
        );
    };

    useEffect(() => {
        if (selectedEquipment) {
            setCount(selectedEquipment.selectedCount);
            setBtnType('added');
        } else {
            setCount(0);
            setBtnType('add');
        }
    }, [selectedEquipment]);

    const onCounterChange = useCallback(
        (val: number) => {
            if (btnType === 'added') {
                setBtnType('update');
            }
            setCount(val);

            if (val > item.qty) {
                dispatch(
                    showSnackBar({
                        content: t('IDS_ERROR_STOCK_DESCRIPTION_unavailable-stock'),
                        type: 'error',
                    })
                );
            }
        },
        [btnType]
    );

    const onBtnClick = useCallback(
        (type: BtnTypes) => {
            switch (type) {
                case 'add':
                case 'update': {
                    if (count === 0) {
                        setBtnType('add');
                        dispatch(deselectEquipment(item.productId));
                        break;
                    }

                    setBtnType('added');
                    selectItems();
                    break;
                }
                case 'added': {
                    setBtnType('add');
                    dispatch(deselectEquipment(item.productId));
                    setCount(0);
                    break;
                }
                default:
                    break;
            }
        },
        [count, setBtnType]
    );

    return (
        <div className='product_card'>
            <div className='information_container'>
                <div className='item_icon'>
                    <ProductIcon productType={item.family} />
                </div>
                <div className='label'>{item.grade}</div>
                <div className='product_description'>
                    <div className='family'>{t(FAMILY_KEYS[item.family])}</div>
                    <ToolTip isActive={withToolTip} content={item.description}>
                        <div ref={titleRef} className='text_information'>
                            {item.description}
                        </div>
                    </ToolTip>

                    <div className='items_count'>
                        {t('IDS_EQUIPMENT_COUNT_available-equipment', { count: item.qty })}
                    </div>
                </div>
            </div>
            <div className='actions_container'>
                <div className='counter'>
                    <CounterButton
                        value={count}
                        onChange={onCounterChange}
                        maxVal={item.qty}
                        isReadOnly={false}
                    />
                </div>
                <div className='card_button'>
                    <ProductCardButton onClick={onBtnClick} type={btnType} />
                </div>
            </div>
        </div>
    );
};
