import cn from 'classnames';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as RightIcon } from '../../../assets/icons/ico-right.svg';
import { useOutsideClick } from '../../../hooks/use-outside-click.hook';
import { useTypedTranslation } from '../../../hooks/use-typed-translation';
import { useGetSupplierClientQuery } from '../../../services/donation.service';
import { selectClientValidationAndSupplierId } from '../../../store/selectors';
import { deselectClient, setClient, setFormValidation } from '../../../store/slice/create-donation.slice';
import { useTypedSelector } from '../../../store/store';
import { Client } from '../../../utils/app.types';
import { Input } from '../../input/input';
import './client-select-dropdown.scss';

type ClientSelectDropdownProps = {
    isSideBarOpen?: boolean;
};
export const ClientSelectDropDown: FC<ClientSelectDropdownProps> = ({ isSideBarOpen }) => {
    const dispatch = useDispatch();
    const [shouldOpenList, setShouldOpenList] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const { supplierId, client, isClientValid, showClientValidError } = useTypedSelector(
        selectClientValidationAndSupplierId
    );
    const [textToSearch, setTextToSearch] = useState('');
    const { t } = useTypedTranslation();
    const { data: clients } = useGetSupplierClientQuery(supplierId, { skip: !supplierId });

    const onInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
        setTextToSearch(target.value);
        setShouldOpenList(true);
    }, []);

    const clientsToDisplay = useMemo(() => {
        const textToSearchL = textToSearch.toLocaleLowerCase();

        if (textToSearchL === client?.name.toLocaleLowerCase() || textToSearchL === '') {
            return clients;
        }

        return clients?.filter((clientToCheck) =>
            clientToCheck.name.toLowerCase().includes(textToSearch.toLowerCase())
        );
    }, [textToSearch, clients]);

    const onClientSelect = useCallback((item: Client) => {
        setShouldOpenList(false);
        setTextToSearch(item.name);
        dispatch(setClient(item));
    }, []);

    useOutsideClick(ref, () => {
        setShouldOpenList(false);
    });

    useEffect(() => {
        if (!shouldOpenList && client) {
            setTextToSearch(client.name);
        }
    }, [client, shouldOpenList]);

    const onArrowClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setShouldOpenList((state) => !state);
    };

    useEffect(() => {
        if (client?.id && clients) {
            const selectedClient = clients?.find((cl) => cl.id === client.id);

            if (selectedClient) {
                onClientSelect(selectedClient);
            }
        } else if (clients?.length === 1) {
            onClientSelect(clients[0]);
        }

        if (clients && clients.length > 1) {
            dispatch(deselectClient());
            setTextToSearch('');
        }
    }, [clients, isSideBarOpen]);

    const validateBeneficiary = () => {
        if (!client) {
            dispatch(setFormValidation({ isClientValid: false, showClientValidError: true }));
        }
    };

    return (
        <div className='beneficiary_select_dropdown'>
            <div className='selection_item'>
                <Input
                    autoComplete='new-password'
                    type='text'
                    id='client_name'
                    name='client_name'
                    label={t('IDS_DONATIONS_ACCOUNT_PLACEHOLDER_choose-account')}
                    value={textToSearch}
                    onChange={onInputChange}
                    onFocus={() => setShouldOpenList(true)}
                    onBlur={() => validateBeneficiary()}
                    error={!isClientValid && t('IDS_GLOBAL_ERROR_required-field')}
                    showValidation={showClientValidError}
                />
                <RightIcon
                    className={cn('right_icon', {
                        up_icon: shouldOpenList,
                        error: showClientValidError,
                    })}
                    onClick={onArrowClick}
                />
            </div>
            {shouldOpenList && (
                <div ref={ref} id='beneficiary_select_list' className='beneficiary_select_list'>
                    <ul>
                        {clientsToDisplay?.map((item) => (
                            <li key={item.id} onClick={() => onClientSelect(item)}>
                                {item.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
