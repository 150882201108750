import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as MenuIcon } from '../../../assets/icons/ico-burger.svg';
import { useGetAccountThemes } from '../../../hooks/use-get-account-themes.hook';
import { PAGES } from '../../../utils/app.enums';
import { Base64Image } from '../../base64-image/base64-image';
import { SideBar } from '../../side-bar/side-bar';
import { NavigationMenu } from '../navigation-menu';
import './navigation-menu-mobile.scss';

export const NavigationMenuMobile = () => {
    const [isNavigationOpen, setIsNavigationOpen] = useState(false);
    const toggleMenu = useCallback(() => setIsNavigationOpen((state) => !state), []);
    const location = useLocation();
    const navigate = useNavigate();

    const { themes, isLoading } = useGetAccountThemes();

    useEffect(() => {
        setIsNavigationOpen(false);
    }, [location]);

    return (
        <div className='container'>
            <div className='mobile_header'>
                <header>
                    <div className='account_logo' onClick={() => navigate(PAGES.dashboard)}>
                        <Base64Image base64Str={themes.logo} isLoading={isLoading} />
                    </div>
                    <div className='icon_placeholder' onClick={toggleMenu}>
                        <MenuIcon />
                    </div>
                </header>
            </div>

            <SideBar covered onOutsideClick={toggleMenu} open={isNavigationOpen}>
                <NavigationMenu
                    onMenuClick={toggleMenu}
                    className='mobile'
                    isMobile={true}
                    mobileOnCloseMenu={toggleMenu}
                />
            </SideBar>
        </div>
    );
};
