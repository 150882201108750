/* istanbul ignore file */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Account } from '../../utils/app.types';

type AccountState = {
    selectedAccount: Account;
    shouldLogOut: boolean;
};

const initialState: AccountState = {
    selectedAccount: {
        name: '',
        id: 0,
    },
    shouldLogOut: false,
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccount: (state, { payload }: PayloadAction<Account>) => {
            state.selectedAccount = payload;
        },

        resetAccount: () => initialState,

        globalLogOut: (state) => {
            state.shouldLogOut = true;
        },
    },
});

export const { setAccount, resetAccount, globalLogOut } = accountSlice.actions;
