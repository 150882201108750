import { useLocation } from 'react-router-dom';

import { useDeviceScreenDetect } from '../../hooks/use-device-screen-detect.hook';
import { ExceptNavPages } from '../../utils/app.enums';
import { NavigationMenuMobile } from '../navigation-menu/menu-mobile/navigation-menu-mobile';
import { NavigationMenu } from '../navigation-menu/navigation-menu';

export const AppNavigationWrapper = () => {
    const { isMobile, isPortraitTablet } = useDeviceScreenDetect();
    const { pathname } = useLocation();

    return (
        <>
            {!ExceptNavPages.has(pathname) && (
                <>{isMobile || isPortraitTablet ? <NavigationMenuMobile /> : <NavigationMenu />}</>
            )}
        </>
    );
};
