import { Dispatch, SetStateAction, useState } from 'react';

import { Func } from '../utils/app.types';

type PaginationOptions = {
    perPage: number;
    page?: number;
    lastPage?: number;
};

export type PaginationHookResult = {
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    perOnePage: number;
    setPerOnePage: Func<number>;
    lastPage: number;
    setLastPage: Func<number>;
};
export const usePaginationHook = ({
    perPage,
    page = 1,
    lastPage = 1,
}: PaginationOptions): PaginationHookResult => {
    const [currentPage, setCurrentPage] = useState(page);
    const [perOnePage, setPerOnePage] = useState(perPage);
    const [_lastPage, _setLastPage] = useState(lastPage);

    return {
        currentPage,
        setCurrentPage,
        perOnePage,
        setPerOnePage,
        lastPage: _lastPage,
        setLastPage: _setLastPage,
    };
};
