import cn from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Loader } from '../../components/loader/loader';
import { Func } from '../../utils/app.types';
import { emptyFn } from '../../utils/helpers.utils';
import './grid-list.scss';

type GridListProps<T = void> = {
    items: Array<T>;
    render: (item: T, index: number) => JSX.Element;
    onObservedItemInVew?: Func;
    itemPositionToObserve?: number;
    remSize?: number;
    className?: string;
    infinityMode?: boolean;
    pending?: boolean;
};

export const GridList = <T = void,>({
    className = 'default',
    infinityMode = false,
    items,
    render,
    pending = false,
    onObservedItemInVew = emptyFn,
    itemPositionToObserve = 0,
}: GridListProps<T>) => {
    const [savedItems, setSavedItems] = useState<Array<T>>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    const { ref, inView, entry } = useInView({ threshold: 1 });
    const [scrollPosition, setScrollPosition] = useState(0);

    const mappedItemPositionToObserve = useMemo(() => {
        const infinityList = savedItems;

        if (infinityList.length) {
            if (itemPositionToObserve >= 0) {
                return itemPositionToObserve;
            }

            if (itemPositionToObserve < 0) {
                return infinityList.length + itemPositionToObserve;
            }
        }
    }, [savedItems.length, itemPositionToObserve]);

    useEffect(() => {
        setSavedItems((state) => state.concat(items));
    }, [items]);

    const listToRender = infinityMode ? savedItems : items;

    const isSingle = listToRender.length === 1;

    useEffect(() => {
        if (inView && infinityMode) {
            onObservedItemInVew();
            if (entry) {
                setScrollPosition(entry.target.parentElement?.scrollLeft ?? 0);
            }
        }
    }, [inView, onObservedItemInVew]);

    useEffect(() => {
        if (infinityMode && containerRef.current) {
            containerRef.current.scrollLeft = scrollPosition * 2;
        }
    }, [listToRender.length]);

    return (
        <div
            ref={containerRef}
            className={cn('grid_list', className, {
                single: isSingle,
                loading: pending,
                no_scroll: listToRender?.length === 0,
            })}>
            {pending && !infinityMode ? (
                <Loader className='list_loader' isDark />
            ) : (
                <>
                    <div className='pre_column'></div>
                    {listToRender.map((item, index) => {
                        if (index === mappedItemPositionToObserve) {
                            return (
                                <div key={'observed_item'} ref={ref}>
                                    {render(item, index)}
                                </div>
                            );
                        }
                        return render(item, index);
                    })}
                    <div className='pre_column'>
                        {infinityMode && pending && <Loader className='list_loader' isDark />}
                    </div>
                </>
            )}
        </div>
    );
};
