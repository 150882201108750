import cn from 'classnames';
import { motion } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../assets/icons/ico-close.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/validation-error.svg';
import { hideSnackBar } from '../../store/slice/snack-bar.slice';
import { useTypedSelector } from '../../store/store';
import { Button } from '../button/button';
import './snack-bar.scss';

export const SnackBar = () => {
    const { type, content, show, errorDescription } = useTypedSelector((state) => state.snackBar);
    const isError = type === 'error';
    const dispatch = useDispatch();

    useEffect(() => {
        if (show && type === 'warning') {
            setTimeout(() => {
                dispatch(hideSnackBar());
            }, 3000);
        }
    }, [show]);

    const closeError = useCallback(() => {
        dispatch(hideSnackBar());
    }, []);

    return (
        <>
            {show && (
                <div className={cn('snack_bar', { error: isError })}>
                    <div className='content'>
                        <div className='icon'>
                            <InfoIcon />
                            {isError && <div>{content}</div>}
                        </div>

                        <div className='description'>{isError ? errorDescription : content}</div>
                    </div>
                    {isError && (
                        <Button className='close_error' onClick={closeError} type='ghost'>
                            <div className='close_text'> Fermer</div>
                            <CloseIcon />
                        </Button>
                    )}

                    <motion.div
                        transition={{ duration: 3, ease: 'linear' }}
                        animate={{ width: isError ? '100%' : '0%' }}
                        className='loader'></motion.div>
                </div>
            )}
        </>
    );
};
