import cn from 'classnames';

import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { PAGES } from '../../utils/app.enums';
import { Copyright } from '../copyright/copyright';
import { ExternalLink } from '../link/external-link';
import './legal-links.scss';

type LegalLinksProps = {
    className?: string;
};
export const LegalLinks = ({ className = 'default' }: LegalLinksProps) => {
    const { t } = useTypedTranslation();

    return (
        <div className={cn('legal_links', className)}>
            <div className='copyright'>
                <Copyright />
            </div>
            <div className='links_list'>
                <ExternalLink spa tab href={PAGES.cookies_declaration}>
                    {t('IDS_LEGAL_cookie-declaration')}
                </ExternalLink>
                <ExternalLink spa tab href={PAGES.mentions_legales}>
                    {t('IDS_LEGAL_legal-mentions')}
                </ExternalLink>
                <ExternalLink spa tab href={PAGES.politique_confidentialite}>
                    {t('IDS_LEGAL_privacy-policy')}
                </ExternalLink>
            </div>
        </div>
    );
};
