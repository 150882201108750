import { useTranslation } from 'react-i18next';

import { TranslationKeys } from '../utils/app.types';

export type TypedTranslationFn = (key: TranslationKeys, count?: { count: number }) => string;

export const useTypedTranslation = () => {
    const { t, i18n } = useTranslation();
    const typedT: TypedTranslationFn = (key, count?) => t(key, count);
    return {
        t: typedT,
        i18n,
    };
};
