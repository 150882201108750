import cn from 'classnames';
import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './App.scss';
import { AppNavigationWrapper } from './components/app-navigation-wrapper/app-navigation-wrapper';
import { SnackBar } from './components/snack-bar/snack-bar';
import { useDeviceScreenDetect } from './hooks/use-device-screen-detect.hook';
import { useLogout } from './hooks/use-logout.hook';
import './i18n';
import { AppRouts } from './router/routing';
import { useTypedSelector } from './store/store';
import { PAGES } from './utils/app.enums';
import { detectRealScreenSize } from './utils/helpers.utils';

function App() {
    const { isMobile, isPortraitTablet } = useDeviceScreenDetect();
    const { pathname } = useLocation();
    const shouldLogout = useTypedSelector((state) => state.account.shouldLogOut);
    const logOut = useLogout();

    const isColumnDirection = pathname !== PAGES.login && !(isMobile || isPortraitTablet);

    useEffect(() => {
        if (shouldLogout) {
            logOut();
        }
    }, [shouldLogout]);

    useLayoutEffect(() => {
        window.addEventListener('resize', detectRealScreenSize);
        detectRealScreenSize();
        return () => window.removeEventListener('resize', detectRealScreenSize);
    }, []);

    useEffect(() => {
        if (location.protocol !== 'https:') {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }
    }, []);

    return (
        <div className={cn('App', { flex_column: isColumnDirection })}>
            <SnackBar />
            <AppNavigationWrapper />
            <div className='page'>
                <AppRouts />
            </div>
        </div>
    );
}

export default App;
