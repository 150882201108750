import cn from 'classnames';

import { PaginationHookResult } from '../../hooks/use-pagination.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import './pagination.scss';

type PaginationProps = {
    totalPages?: number;
    className?: string;
} & PaginationHookResult;

export const Pagination = ({
    totalPages = 1,
    currentPage,
    setCurrentPage,
    className = 'default',
}: PaginationProps) => {
    const { t } = useTypedTranslation();

    const paginationElements = () => {
        const elements = new Array<JSX.Element>();
        let startPage = 1;
        let listLength = 1;
        const isInEnd = currentPage < totalPages - 2;

        if (totalPages <= 4) {
            for (let index = 1; index <= totalPages; index++) {
                elements.push(
                    <div
                        key={index}
                        onClick={() => setCurrentPage(index)}
                        className={cn('pagination_item', { active: currentPage === index })}>
                        {index}
                    </div>
                );
            }
            return elements;
        }

        if (currentPage === 1) {
            listLength = 4;
        } else if (currentPage >= totalPages - 2) {
            startPage = totalPages - 3;
            listLength = totalPages - 1;
        } else if (currentPage === 2) {
            startPage = currentPage - 1;
            listLength = currentPage + 2;
        } else {
            startPage = currentPage - 1;
            listLength = currentPage + 1;
        }

        if (currentPage > 2) {
            elements.push(
                <div
                    key={'first_item'}
                    onClick={() => setCurrentPage(1)}
                    className={cn('pagination_item', { active: currentPage === 1 })}>
                    1
                </div>
            );
            if (currentPage > 3) {
                elements.push(
                    <div key={'separator_first'} className='separator'>
                        ....
                    </div>
                );
            }
        }

        for (let index = startPage; index <= listLength; index++) {
            elements.push(
                <div
                    key={index}
                    onClick={() => setCurrentPage(index)}
                    className={cn('pagination_item', { active: currentPage === index })}>
                    {index}
                </div>
            );
        }

        if (isInEnd) {
            elements.push(
                <div key={'separator'} className='separator'>
                    ....
                </div>
            );

            elements.push(
                <div
                    key={'last_item'}
                    onClick={() => setCurrentPage(totalPages)}
                    className={cn('pagination_item', { active: currentPage === totalPages })}>
                    {totalPages}
                </div>
            );
        } else {
            elements.push(
                <div
                    key={'last_item'}
                    onClick={() => setCurrentPage(totalPages)}
                    className={cn('pagination_item', { active: currentPage === totalPages })}>
                    {totalPages}
                </div>
            );
        }

        return elements;
    };

    const onIncrementClick = (direction: 'left' | 'right') => () => {
        if (direction === 'left') {
            setCurrentPage((page) => (page === 1 ? page : page - 1));
        } else {
            setCurrentPage((page) => (page === totalPages ? page : page + 1));
        }
    };

    if (totalPages <= 1) {
        return null;
    }
    return (
        <div className={cn('pagination', className)}>
            <div className='action' onClick={onIncrementClick('left')}>
                {t('IDS_GLOBAL_previous')}
            </div>
            <div className='pagination_items_container'>{paginationElements()}</div>
            <div className='action next' onClick={onIncrementClick('right')}>
                {t('IDS_GLOBAL_next')}
            </div>
        </div>
    );
};
