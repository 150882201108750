import { FC, useEffect, useRef } from 'react';

const CookiesDeclarationPage: FC = () => {
    const cookieContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (cookieContainerRef.current) {
            const script = document.createElement('script');
            script.src = 'https://consent.cookiebot.com/9560c378-61a2-4059-bc24-c800cc070d49/cd.js';
            script.type = 'text/javascript';
            script.async = true;

            cookieContainerRef.current.append(script);
        }
    });

    return (
        <div className='cookies-declaration'>
            <div ref={cookieContainerRef} />
        </div>
    );
};

export default CookiesDeclarationPage;
