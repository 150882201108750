import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import './external-link.scss';

type ExternalLinkProps = {
    href: string;
    children: React.ReactNode;
    spa?: boolean;
    tab?: boolean;
    className?: string;
};
export const ExternalLink = ({
    className = 'default',
    href,
    children,
    spa = false,
    tab = false,
}: ExternalLinkProps) => (
    <>
        {spa ? (
            <Link target={tab ? '_blank' : '_self'} className={cn('external_link', className)} to={href}>
                {children}
            </Link>
        ) : (
            <a
                rel='noreferrer'
                target={tab ? '_blank' : '_self'}
                className={cn('external_link', className)}
                href={href}>
                {children}
            </a>
        )}
    </>
);
