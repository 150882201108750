import cn from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Func } from '../../utils/app.types';
import { emptyFn } from '../../utils/helpers.utils';
import './side-bar.scss';

export type SideBarProps = {
    children: React.ReactNode;
    className?: string;
    open: boolean;
    covered?: boolean;
    onOutsideClick?: () => void;
    onAnimationEnd?: Func;
};

const variants = {
    open: { opacity: 1, x: '0%' },
    closed: { opacity: 0, x: '100%' },
};

export const SideBar = ({
    open,
    children,
    className = 'default',
    onOutsideClick = emptyFn,
    onAnimationEnd = emptyFn,
}: SideBarProps) => {
    const [isReady, setIsReady] = useState(false);
    const refSideBar = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = event.target as HTMLDivElement;

        if (target.classList.contains('side_bar')) {
            onOutsideClick();
        }
    };

    useLayoutEffect(() => {
        if (refSideBar.current) {
            setIsReady(true);
        }
    }, []);

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'inherit';
        }

        return () => {
            document.body.style.overflow = 'inherit';
        };
    }, [open]);

    const onAnimationCloseEnd = () => {
        if (!open) {
            onAnimationEnd();
        }
    };

    return (
        <div
            ref={refSideBar}
            onMouseDown={handleOutsideClick}
            className={cn('side_bar', className, {
                open: open,
                hidden: !isReady,
            })}>
            <motion.div
                exit={variants.closed}
                onAnimationEnd={onAnimationCloseEnd}
                transition={{ ease: 'easeInOut' }}
                animate={open ? 'open' : 'closed'}
                initial={variants.closed}
                variants={variants}
                className='side_bar_content'>
                {<>{children}</>}
            </motion.div>
        </div>
    );
};
