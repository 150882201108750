import cn from 'classnames';
import { useCallback, useState } from 'react';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/ico-down.svg';
import { useGetAccountsQuery } from '../../../services/base.service';
import { selectSupplierName } from '../../../store/selectors';
import { useTypedSelector } from '../../../store/store';
import { AccountSelectModalContent } from '../../account-select-modal-content/account-select-modal-content';
import { AppModal } from '../../app-modal/app-modal';
import './account-select-button.scss';

export const AccountSelect = () => {
    const name = useTypedSelector(selectSupplierName);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { count } = useGetAccountsQuery(undefined, {
        selectFromResult: ({ data, isLoading }) => {
            return { count: data?.length || 0, isLoading };
        },
    });

    const toggleModal = useCallback(() => setIsModalOpen((state) => !state), []);

    return (
        <>
            <AppModal isOpen={isModalOpen} onClose={toggleModal} id='test'>
                <AccountSelectModalContent onSelect={toggleModal} />
            </AppModal>

            <div
                className={cn('account_select', { non_interactive: count === 1 })}
                onClick={toggleModal}>
                <div className={cn('account_name')}>{name}</div>
                {count > 1 && (
                    <div className='drop_down_arrow'>
                        <ArrowIcon />
                    </div>
                )}
            </div>
        </>
    );
};
