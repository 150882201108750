import cn from 'classnames';
import { FC, useCallback, useMemo } from 'react';

import { ReactComponent as BeneficiaryIcon } from '../../../assets/icons/ico-beneficiaire.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/ico-close.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icons/ico-wallet.svg';
import { useTypedTranslation } from '../../../hooks/use-typed-translation';
import { useGetPriceQuery } from '../../../services/donation.service';
import { selectEquipmentsArraySideBar, selectProductCount, selectSupplierId } from '../../../store/selectors';
import { useTypedSelector } from '../../../store/store';
import { EURO, OPTION_VARIANTS } from '../../../utils/app.enums';
import { DonationInformation, DonationOption, Func } from '../../../utils/app.types';
import { DonationStatusLabel } from '../../donation-status-label/donation-status-label';
import { Loader } from '../../loader/loader';
import { SideBar, SideBarProps } from '../../side-bar/side-bar';
import { SidebarEquipmentCard } from '../sidebar-equipment-card/sidebar-equipment-card';
import './overview-sidebar.scss';

type DonationOverviewSideBarProps = {
    donation?: DonationInformation;
    onAnimationEnd?: Func;
    isLoading: boolean;
} & Omit<SideBarProps, 'children' | 'covered'>;

const isOptionFree = (option: DonationOption) => option.name.includes('-FREE');

export const DonationOverviewSideBar: FC<DonationOverviewSideBarProps> = ({
    donation,
    open,
    onOutsideClick,
    onAnimationEnd,
    isLoading,
}) => {
    const { t } = useTypedTranslation();
    const supplierId = useTypedSelector(selectSupplierId);
    const productCount = useTypedSelector(selectProductCount);

    const equipment = useTypedSelector(selectEquipmentsArraySideBar);
    const optionsToDisplay = useMemo(() => donation?.options, [donation?.options]);
    const { data: price = 0 } = useGetPriceQuery(supplierId);

    const pricePerDonation = useMemo(() => productCount * price, [productCount, price]);
    const optionsTotalPrice = useMemo(() => {
        const calculatePrice = optionsToDisplay?.reduce((prev, current) => prev + current.total, 0) ?? 0;
        return calculatePrice + pricePerDonation;
    }, [optionsToDisplay, price, pricePerDonation]);

    const getOptionName = useCallback(
        (option: DonationOption) => {
            if (isOptionFree(option)) {
                const name = option.name.replace('-FREE', '');
                const [translatedName] = t(OPTION_VARIANTS[name], { count: 0 }).split(':');
                return translatedName;
            }
            return t(OPTION_VARIANTS[option.name], { count: option.unitPrice });
        },
        [t]
    );

    return (
        <SideBar open={open} onOutsideClick={onOutsideClick} onAnimationEnd={onAnimationEnd}>
            <div className={cn('donation_overview', { loading: !isLoading })}>
                <div className='close_icon'>
                    <div className='icon_container' onClick={onOutsideClick}>
                        <CloseIcon />
                    </div>
                </div>

                {isLoading && <Loader isDark isAbsolute />}
                {!isLoading && (
                    <>
                        <div className='donation_meta_data'>
                            <div className='donation_id'>{donation?.donationId}</div>
                            <div className='donation_status'>
                                <DonationStatusLabel status={donation?.status} />
                            </div>
                        </div>
                        <div className='donation_sidebar_title'>{donation?.donationName}</div>
                        {donation?.date && <div className='date'>{donation?.date}</div>}
                        <div className='donation_equipment_list'>
                            {equipment?.map((product) => (
                                <SidebarEquipmentCard key={product.id} item={product} isReadOnly={true} />
                            ))}
                        </div>
                        <div className='donation_options'>
                            {optionsToDisplay?.map((option) => (
                                <div key={option.name} className='option'>
                                    <div className='name'>{getOptionName(option)}</div>
                                    <div className='total'>
                                        {isOptionFree(option)
                                            ? t('IDS_DONATIONS_PRICE_free')
                                            : `${option.total} ${EURO}`}
                                    </div>
                                </div>
                            ))}

                            {optionsToDisplay && (
                                <div className='price_container'>
                                    <div className='price_row underline'>
                                        <div>{t('IDS_DONATION_donation-price')}</div>
                                        <div>{`${pricePerDonation} ${EURO}`}</div>
                                    </div>
                                    <div className='price_row'>
                                        <div>{t('IDS_DONATION_donation-totall')}</div>
                                        <div>
                                            {optionsTotalPrice} {EURO}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='sidebar_beneficiary_select_container'>
                            <div className='sidebar_beneficiary_section_name'>
                                <div className='beneficiary_icon'>
                                    <BeneficiaryIcon />
                                </div>
                                {t('IDS_MENU_beneficiairies')}
                            </div>

                            <div className='beneficiary_short_data'>
                                <div className='info_row'>{donation?.beneficiaryName}</div>
                                <div className='info_row'>{donation?.address}</div>
                                <div className='info_row'>{`${donation?.zip_code} ${donation?.city}`}</div>
                            </div>
                        </div>
                        <div className='sidebar_beneficiary_select_container client'>
                            <div className='sidebar_beneficiary_section_name'>
                                <div className='beneficiary_icon'>
                                    <WalletIcon />
                                </div>
                                {t('IDS_DONATIONS_ACCOUNT_billed-account')}
                            </div>
                            <div className='beneficiary_short_data'>
                                <div className='info_row'>{donation?.customerName}</div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </SideBar>
    );
};
