import { ExternalLink } from '../../components/link/external-link';
import { useGetUserInformation } from '../../hooks/use-get-me.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';

const SUBJECT = 'Ajouter mon bénéficiaire myatf Donation';
const BODY = `
• Nom du bénéficiaire : %0D%0A
• Nom du contact : %0D%0A
• Numéro de téléphone du contact : %0D%0A
• Adresse e-mail du contact : %0D%0A
• Adresse 1 : %0D%0A
• Adresse 2 : %0D%0A
• Code postal : %0D%0A
• Ville : %0D%0A
• Pays : %0D%0A
%0D%0A%0D%0A%0D%0A

L'équipe myatf Donation
`;

export const CreateBeneficiaryLink = () => {
    const { userData } = useGetUserInformation();
    const { t } = useTypedTranslation();

    return (
        <ExternalLink
            href={`mailto:${userData?.support?.email}?subject=${SUBJECT}&body=${BODY}`}
            tab
            className='create_benef_link'>
            {t('IDS_BENEFICIAIRIES_CTA_add-a-beneficiairy')}
        </ExternalLink>
    );
};
