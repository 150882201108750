import cn from 'classnames';
import React from 'react';

import { Loader } from '../loader/loader';
import './button.scss';

type ButtonProps = {
    children: React.ReactNode;
    type?: 'primary' | 'ghost' | 'tertiary';
    isDisabled?: boolean;
    onClick(): void;
    isLoading?: boolean;
    className?: string;
    isDarkLoader?: boolean;
};

export function Button({
    children,
    onClick,
    type = 'primary',
    isLoading = false,
    isDisabled = false,
    className = 'default',
    isDarkLoader,
}: ButtonProps) {
    const onButtonClick = () => {
        if (!isDisabled) {
            onClick();
        }
    };
    return (
        <button
            data-testid='main_btn'
            type={'button'}
            onClick={onButtonClick}
            className={cn('main_btn', type, className, { disabled: isDisabled })}>
            {isLoading ? <Loader isDark={isDarkLoader} /> : children}
        </button>
    );
}
