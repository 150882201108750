import { motion } from 'framer-motion';
import { FC } from 'react';

import { ReactComponent as ErrorIcon } from '../../../assets/icons/validation-error.svg';
import { useTypedTranslation } from '../../../hooks/use-typed-translation';

type StockErrorProps = {
    showError?: boolean;
};

const initialValues = {
    opacity: 0,
    maxHeight: '0px',
};

const animate = {
    maxHeight: '101px',
    opacity: 1,
    paddingBottom: ' 0.5rem',
};

const variants = {
    closed: initialValues,
    open: animate,
};

export const StockError: FC<StockErrorProps> = ({ showError }) => {
    const { t } = useTypedTranslation();

    return (
        <motion.div
            initial={initialValues}
            animate={showError ? 'open' : 'close'}
            variants={variants}
            transition={{ duration: 0.5 }}
            className='stock_error'>
            <div className='error_icon'>
                <ErrorIcon />
            </div>
            <div className='error_text'>
                <div className='error_title'>{t('IDS_ERROR_STOCK_TITLE_unavailable-stock')}</div>
                <div className='error_description'>{t('IDS_ERROR_STOCK_DESCRIPTION_unavailable-stock')}</div>
            </div>
        </motion.div>
    );
};
