import cn from 'classnames';
import { useState } from 'react';

import './multi-select.scss';

export type MultiSelectItemsList = {
    value: unknown;
    label: string;
    isSelected: boolean;
};

export type MultiSelectList<T = 'check-box' | 'radio-btn'> = T extends 'check-box'
    ? {
          type: T;
          items: Array<MultiSelectItemsList>;
          onChange(selectedItems: Array<unknown>): void;
      }
    : {
          type: T;
          items: Array<MultiSelectItemsList>;
          onChange(selectedItems: unknown): void;
      };

export const MultiSelect = ({ type, items, onChange }: MultiSelectList) => {
    const [stateItems, setSelectedItems] = useState(items);
    const isCheckBox = type === 'check-box';

    const onItemChange = (changedItem: MultiSelectItemsList) => {
        const copyOfItems = [...stateItems];
        const indexItem = copyOfItems.findIndex((item) => changedItem.value === item.value);

        if (type === 'radio-btn') {
            copyOfItems.forEach((item) => (item.isSelected = false));
        }
        copyOfItems[indexItem].isSelected = !changedItem.isSelected;

        setSelectedItems(copyOfItems);

        const selectedValues = copyOfItems
            .filter((item) => item.isSelected)
            .map((item) => item.value);

        if (type === 'check-box') {
            onChange(selectedValues);
        }
        if (type === 'radio-btn') {
            onChange(selectedValues[0]);
        }
    };

    return (
        <div className='multi_select_container'>
            <>
                {stateItems.map((item) => (
                    <div
                        key={item.label}
                        className='input_group'
                        onClick={() => onItemChange(item)}>
                        <div
                            className={cn({
                                checked: item.isSelected,
                                box: isCheckBox,
                                circle: !isCheckBox,
                            })}
                        />
                        <label className={'label'}>{item.label}</label>
                    </div>
                ))}
            </>
        </div>
    );
};
