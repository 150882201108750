import cn from 'classnames';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as BeneficiaryIcon } from '../../assets/icons/ico-beneficiaire.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/ico-close.svg';
import { ReactComponent as WalletIcon } from '../../assets/icons/ico-wallet.svg';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import {
    useCreateDonationMutation,
    useGetDonationOptionsQuery,
    useSaveDraftDonationMutation,
    useUpdateDraftDonationMutation,
} from '../../services/donation.service';
import { selectCreateDonationForm, selectIsDonationValidForCreation } from '../../store/selectors';
import { resetForm, updateStockErrorsAndQuantity } from '../../store/slice/create-donation.slice';
import { useTypedSelector } from '../../store/store';
import { DonationOption, Func } from '../../utils/app.types';
import { formatCreateDonationForm, mapFamily } from '../../utils/helpers.utils';
import { Button } from '../button/button';
import { DonationStatusLabel } from '../donation-status-label/donation-status-label';
import { Loader } from '../loader/loader';
import { SideBar } from '../side-bar/side-bar';
import { BeneficiarySelectDropdown } from './beneficiary-select-dropdown/beneficiary-select-dropdown';
import { ClientSelectDropDown } from './client-select-dropdown/client-select-dropdown';
import './create-donation-sidebar.scss';
import { DonationNameInput } from './donation-name-input/donation-name-input';
import { SidebarEquipmentCard } from './sidebar-equipment-card/sidebar-equipment-card';
import { SideBarOptions } from './sidebar-options/sidebar-options';
import { ValidationSideBar } from './validation-sidebar/validation-sidebar';

type CreteDonationSideBarProps = {
    onClose: Func;
    isOpen: boolean;
    beneficiaryId?: number;
    savedOptions?: DonationOption[];
    onAnimationEnd?: Func;
    isLoading?: boolean;
};

export const UpdateDonationSideBar: FC<CreteDonationSideBarProps> = ({
    onClose,
    isOpen,
    onAnimationEnd,
    isLoading,
}) => {
    const { t } = useTypedTranslation();
    const {
        supplierId,
        products,
        options: selectedOptions,
        beneficiary,
        donationName,
        isUpdateMode,
        donationId,
        status,
        date,
        client,
    } = useTypedSelector(selectCreateDonationForm);
    const isFormValid = useTypedSelector(selectIsDonationValidForCreation);

    const [showValidationSideBar, setShowValidationSideBar] = useState(false);
    const [validateDonation, { isLoading: createDonationLoading }] = useCreateDonationMutation();
    const [saveDraftDonation, { isLoading: saveDraftLoading }] = useSaveDraftDonationMutation();
    const [updateDraftDonation, { isLoading: updateDraftLoading }] = useUpdateDraftDonationMutation();
    const { data: optionsList, isFetching } = useGetDonationOptionsQuery(
        {
            families: mapFamily(products),
        },
        { skip: !products.length }
    );

    const onCloseValidationSideBar = useCallback(() => {
        setShowValidationSideBar(false);
        onClose();
        dispatch(resetForm());
    }, []);
    const dispatch = useDispatch();

    const onDonationCreate = async () => {
        const formData = {
            donationName,
            equipmentsList: products,
            beneficiary,
            options: selectedOptions,
            donationId,
        };
        const formattedData = formatCreateDonationForm(formData, supplierId, client?.id);
        if (formattedData && isFormValid) {
            const response = await validateDonation(formattedData);

            if ('data' in response) {
                if ('failedProducts' in response.data) {
                    dispatch(updateStockErrorsAndQuantity(response.data.failedProducts));
                } else {
                    setShowValidationSideBar(true);
                }
            }
        }
    };

    const onDraftSave = async () => {
        const formData = {
            donationName,
            equipmentsList: products,
            beneficiary,
            options: selectedOptions,
            donationId,
        };
        const formattedData = formatCreateDonationForm(formData, supplierId, client?.id);

        if (formattedData && isFormValid) {
            if (isUpdateMode) {
                updateDraftDonation(formattedData);
                dispatch(resetForm());
                onClose();
            } else {
                await saveDraftDonation(formattedData);
                dispatch(resetForm());
                onClose();
            }
        }
    };

    const dateToDisplay = useMemo(() => {
        const date = new Date();

        return `${date.getDate()}/${date.getMonth() + 1}/${date.getUTCFullYear()}`;
    }, []);

    return (
        <>
            <ValidationSideBar
                open={showValidationSideBar}
                close={onCloseValidationSideBar}
                donationName={donationName}
            />
            <SideBar
                open={isOpen && !showValidationSideBar}
                onOutsideClick={onClose}
                onAnimationEnd={onAnimationEnd}>
                <div className={cn('create_donation_sidebar updated', { loading: !isLoading })}>
                    <div className='close_icon'>
                        <div className='icon_container' onClick={() => onClose()}>
                            <CloseIcon />
                        </div>
                    </div>
                    {isLoading && <Loader isDark isAbsolute />}

                    {!isLoading && (
                        <>
                            <div className='donation_status'>
                                <div className='donation_id'>{donationId}</div>
                                <DonationStatusLabel status={status} />
                            </div>
                            <div className='donation_sidebar_title'>
                                {donationName || t('IDS_DONATION_my-donation')}
                            </div>
                            <div className='date'>{date || dateToDisplay}</div>
                            <DonationNameInput donationName={donationName} />
                            <div className='equipments_container'>
                                {products?.map((product) => (
                                    <SidebarEquipmentCard
                                        isReadOnly={false}
                                        key={product.id}
                                        item={product}
                                    />
                                ))}
                            </div>

                            <div className='sidebar_donation_options_container'>
                                {!!products?.length && (
                                    <SideBarOptions
                                        optionsList={optionsList}
                                        isFetching={isFetching}
                                        selectedOptions={selectedOptions}
                                    />
                                )}
                            </div>
                            <div className='sidebar_beneficiary_select_container'>
                                <div className='sidebar_beneficiary_section_name'>
                                    <div className='beneficiary_icon'>
                                        <BeneficiaryIcon />
                                    </div>

                                    {t('IDS_MENU_beneficiairies')}
                                </div>
                                <div className='select_beneficiary_input_container'>
                                    <BeneficiarySelectDropdown />
                                </div>
                                {beneficiary && (
                                    <>
                                        <div className='beneficiary_short_data'>
                                            <div className='info_row'>{beneficiary.nameBeneficiary}</div>
                                            <div className='info_row'>{beneficiary.adresse}</div>
                                            <div className='info_row'>{beneficiary.adress_bis}</div>
                                            <div className='info_row'>{`${beneficiary.zip_code} ${beneficiary.city}`}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='sidebar_beneficiary_select_container client'>
                                <div className='sidebar_beneficiary_section_name'>
                                    <div className='beneficiary_icon'>
                                        <WalletIcon />
                                    </div>
                                    {t('IDS_DONATIONS_ACCOUNT_billed-account')}
                                </div>
                                <div className='select_beneficiary_input_container'>
                                    <ClientSelectDropDown isSideBarOpen={isOpen} />
                                </div>
                            </div>
                            <div className='sidebar_donation_buttons'>
                                <Button
                                    isDisabled={!isFormValid}
                                    className='sidebar_btn validate_donation_btn'
                                    isLoading={createDonationLoading}
                                    onClick={onDonationCreate}
                                    type='primary'>
                                    {t('IDS_DONATIONS_VALIDATION_CTA_validate-donation')}
                                </Button>
                                <Button
                                    isDisabled={!isFormValid}
                                    className='sidebar_btn save_donation_btn'
                                    isLoading={isUpdateMode ? updateDraftLoading : saveDraftLoading}
                                    onClick={onDraftSave}
                                    isDarkLoader
                                    type='ghost'>
                                    {t('IDS_DONATIONS_VALIDATION_CTA_save-to-drafts')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </SideBar>
        </>
    );
};
