import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as InfoIcon } from '../../../assets/icons/ico-information.svg';
import { TypedTranslationFn, useTypedTranslation } from '../../../hooks/use-typed-translation';
import { useGetPriceQuery } from '../../../services/donation.service';
import { selectProductCount, selectSupplierId } from '../../../store/selectors';
import { setOption } from '../../../store/slice/create-donation.slice';
import { useTypedSelector } from '../../../store/store';
import { EURO } from '../../../utils/app.enums';
import { DonationOption } from '../../../utils/app.types';
import { Loader } from '../../loader/loader';
import { ToolTip } from '../../tooltip/tooltip';
import { Option } from './option';
import './sidebar-options.scss';

type SideBarProps = {
    optionsList?: DonationOption[];
    selectedOptions: DonationOption[];
    isFetching: boolean;
};

export const adjustOptionDescription = (description: string) => {
    return description.split('\\n\r');
};
export const getIsOptionSelected = (option: DonationOption, selectedOptions: DonationOption[]) => {
    return selectedOptions.some((item) => item.name === option.name);
};

export const OptionTooltip = ({ t }: { t: TypedTranslationFn }) => (
    <div className='option_tooltip_with_icon'>
        <div className='info_icon'>
            <InfoIcon />
        </div>

        <div className='tooltip_description'>
            {adjustOptionDescription(t('IDS_DONATIONS_OPTIONS_DETAILS_options-stock-details')).map((line) => (
                <div key={line}>
                    <div>{line}</div>
                </div>
            ))}
        </div>
    </div>
);

export const SideBarOptions: FC<SideBarProps> = ({ optionsList, selectedOptions, isFetching }) => {
    const { t } = useTypedTranslation();
    const supplierId = useTypedSelector(selectSupplierId);
    const productCount = useTypedSelector(selectProductCount);

    const dispatch = useDispatch();
    const { data: price = 0 } = useGetPriceQuery(supplierId);

    const onOptionClick = useCallback(
        (option: DonationOption) => {
            const isOptionSelected = getIsOptionSelected(option, selectedOptions);

            if (isOptionSelected) {
                const options = selectedOptions.filter((item) => item.name !== option.name);
                dispatch(setOption(options));
            } else {
                dispatch(setOption([...selectedOptions, option]));
            }
        },
        [selectedOptions]
    );

    const pricePerDonation = useMemo(() => productCount * price, [productCount, price]);

    const optionsTotalPrice = () => {
        const calculatePrice =
            optionsList
                ?.filter((option) => getIsOptionSelected(option, selectedOptions))
                ?.reduce((prev, current) => prev + current.total, 0) ?? 0;
        return calculatePrice + pricePerDonation;
    };

    useEffect(() => {
        if (selectedOptions.length) {
            const filteredOptions = selectedOptions?.filter(({ name }) =>
                optionsList?.some((opt) => opt.name === name)
            );
            dispatch(setOption(filteredOptions));
        }
    }, [optionsList]);

    return (
        <div className='sidebar_options'>
            <div className='info_container'>
                <div className='options_title'>{t('IDS_EQUIPMENT_OPTIONS_TITLE_add-an-option')}</div>
                <div className='info_icon'>
                    <ToolTip content={<OptionTooltip t={t} />}>
                        <InfoIcon />
                    </ToolTip>
                </div>
            </div>
            {isFetching && <Loader isAbsolute isDark />}

            {!isFetching && (
                <>
                    <div className='options_list'>
                        {optionsList?.map((option) => (
                            <Option
                                isOptionSelected={getIsOptionSelected(option, selectedOptions)}
                                onClick={onOptionClick}
                                option={option}
                                key={option.name}
                            />
                        ))}
                    </div>
                    <div className='price_container'>
                        <div className='price_row underline'>
                            <div>{t('IDS_DONATION_donation-price')}</div>
                            <div>{`${pricePerDonation} ${EURO}`}</div>
                        </div>
                        <div className='price_row'>
                            <div>{t('IDS_DONATION_donation-totall')}</div>
                            <div>
                                {optionsTotalPrice()} {EURO}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
