import React, { useLayoutEffect, useState } from 'react';

export const useCheckOverflow = <T extends HTMLElement>(ref: React.RefObject<T>) => {
    const [hasOverflow, setHasOverflow] = useState(false);

    useLayoutEffect(() => {
        const titleDiv = ref.current;
        if (titleDiv && titleDiv.offsetWidth < titleDiv.scrollWidth) {
            setHasOverflow(true);
        }
    }, []);

    return hasOverflow;
};
