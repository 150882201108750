import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import i18n from '../i18n';
import { baseApi, useLogOutMutation } from '../services/base.service';
import { resetAccount } from '../store/slice/account.slice';
import { showSnackBar } from '../store/slice/snack-bar.slice';
import { PAGES } from '../utils/app.enums';
import { resetThemeColors } from '../utils/helpers.utils';

export const useLogout = () => {
    const [requestLogOut] = useLogOutMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logOut = useCallback(() => {
        requestLogOut().finally(() => {
            dispatch(baseApi.util.resetApiState());
            dispatch(resetAccount());
            dispatch(
                showSnackBar({
                    content: i18n.t('IDS_VALIDATION_MESSAGE_you-are-logged-out'),
                    type: 'warning',
                })
            );
            resetThemeColors();
            navigate(PAGES.login);
        });
    }, []);

    return logOut;
};
