import { FC } from 'react';

import { ReactComponent as AddIcon } from '../../../assets/icons/ico-add.svg';
import { ReactComponent as LoaderIcon } from '../../../assets/icons/ico-loading.svg';
import { ReactComponent as ValidateIcon } from '../../../assets/icons/ico-validate.svg';
import { useTypedTranslation } from '../../../hooks/use-typed-translation';
import { Func } from '../../../utils/app.types';
import { Button } from '../../button/button';
import './product-card-button.scss';

type ProductCardButtonProps = {
    type: 'add' | 'added' | 'update';
    onClick: Func<'add' | 'added' | 'update'>;
};

type FCWitchChild = {
    children: React.ReactNode;
};
export const ProductCardButton: FC<ProductCardButtonProps> = ({ type, onClick }: ProductCardButtonProps) => {
    const ProductCardButtonContainer: FC<FCWitchChild> = ({ children }) => (
        <div className='product_card_button'>{children}</div>
    );
    const { t } = useTypedTranslation();

    if (type === 'add') {
        return (
            <ProductCardButtonContainer>
                <Button type={'tertiary'} onClick={() => onClick('add')}>
                    <div className='icon ghost'>
                        <AddIcon />
                    </div>
                    {t('IDS_EQUIPMENT_DETAILS_BUTTON_add-to-donation')}
                </Button>
            </ProductCardButtonContainer>
        );
    } else if (type === 'update') {
        return (
            <ProductCardButtonContainer>
                <Button className='active' type={'tertiary'} onClick={() => onClick('update')}>
                    <div className='icon spin'>
                        <LoaderIcon />
                    </div>
                    Mettre à jour
                </Button>
            </ProductCardButtonContainer>
        );
    }
    return (
        <ProductCardButtonContainer>
            <Button className='active' type={'tertiary'} onClick={() => onClick('added')}>
                <div className='icon'>
                    <ValidateIcon />
                </div>
                {t('IDS_EQUIPMENT_STATUS_added')}
            </Button>
        </ProductCardButtonContainer>
    );
};
