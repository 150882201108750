import { FC, useEffect, useState } from 'react';

import { GDPR_PAGES } from '../../utils/app.enums';

const noContent = '<p style=text-align:center>No Content</p>';

export const ExternalLegalPageLoader: FC<{ legalPageName: GDPR_PAGES }> = ({ legalPageName }) => {
    const [pageContent, setPageContent] = useState('');

    useEffect(() => {
        fetch(`/${legalPageName}`)
            .then((response) => response.text())
            .then((data) => setPageContent(data))
            .catch(() => setPageContent(noContent));
    }, []);

    return (
        <div data-testid='external-data' dangerouslySetInnerHTML={{ __html: pageContent }}></div>
    );
};
