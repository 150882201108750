/* istanbul ignore file */
import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';

import i18n from '../i18n';
import { globalLogOut } from '../store/slice/account.slice';
import { showSnackBar } from '../store/slice/snack-bar.slice';
import { PAGES } from '../utils/app.enums';

export const authGuardMiddleware: Middleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action)) {
            if (action.payload.status === 401 && location.pathname !== PAGES.login) {
                dispatch(globalLogOut());
            } else if (action.payload.status === 'FETCH_ERROR') {
                dispatch(
                    showSnackBar({
                        content: i18n.t('IDS_ERROR_GLOBAL_error-happened'),
                        errorDescription: i18n.t('IDS_ERROR_GLOBAL_please-refresh'),
                        type: 'error',
                    })
                );
            }
        }

        return next(action);
    };
