import cn from 'classnames';

import { Loader } from '../loader/loader';
import './base64-image.scss';

type Base64ImageProps = {
    base64Str: string;
    isLoading: boolean;
};

export const Base64Image = ({ base64Str, isLoading }: Base64ImageProps) => {
    return (
        <div className='base64-image'>
            <img
                className={cn({ image_loading: isLoading })}
                src={`data:image/png;base64, ${base64Str}`}
            />
            {isLoading && (
                <div className='app_loader_container'>
                    <Loader isAbsolute isDark />
                </div>
            )}
        </div>
    );
};
