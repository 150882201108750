/* istanbul ignore file */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    BeneficiaryCreate,
    BeneficiaryDelete,
    BeneficiaryPaginationResponse,
    PaginationRequest,
    ServerResponseBase,
} from '../utils/app.types';
import { baseApi } from './base.service';

export const beneficiaryApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createBeneficiary: builder.mutation<ServerResponseBase<void>, BeneficiaryCreate>({
            query: (beneficiaryCreate) => ({
                url: 'Beneficiary/create',
                body: [beneficiaryCreate],
                credentials: 'include',
                method: 'POST',
            }),
            invalidatesTags: ['BENEFICIARY'],
        }),
        updateBeneficiary: builder.mutation<ServerResponseBase<void>, BeneficiaryCreate>({
            query: (beneficiaryUpdate) => ({
                url: 'Beneficiary/update',
                body: [beneficiaryUpdate],
                credentials: 'include',
                method: 'POST',
            }),
            invalidatesTags: ['BENEFICIARY'],
        }),
        deleteBeneficiary: builder.mutation<ServerResponseBase<void>, BeneficiaryDelete>({
            query: (beneficiaryDelete) => ({
                url: 'Beneficiary/delete',
                body: [beneficiaryDelete],
                credentials: 'include',
                method: 'POST',
            }),
            invalidatesTags: ['BENEFICIARY'],
        }),
        listOfBeneficiary: builder.query<BeneficiaryPaginationResponse, PaginationRequest>({
            query: (beneficiaryPage) => ({
                url: 'Beneficiary/list',
                body: [beneficiaryPage],
                credentials: 'include',
                method: 'POST',
            }),
            providesTags: ['BENEFICIARY'],
            transformResponse: (resp: any) => resp.result,
        }),
        communsBeneficiary: builder.query<BeneficiaryPaginationResponse, PaginationRequest>({
            query: (beneficiaryPage) => ({
                url: 'Beneficiary/communs',
                body: [beneficiaryPage],
                credentials: 'include',
                method: 'POST',
            }),
            providesTags: ['BENEFICIARY'],
            transformResponse: (resp: any) => resp.result,
        }),
    }),
});

export const {
    useCreateBeneficiaryMutation,
    useUpdateBeneficiaryMutation,
    useDeleteBeneficiaryMutation,
    useLazyListOfBeneficiaryQuery,
    useListOfBeneficiaryQuery,
    useCommunsBeneficiaryQuery,
    useLazyCommunsBeneficiaryQuery,
} = beneficiaryApi;
