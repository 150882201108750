import { ExternalLegalPageLoader } from '../../components/external-legal-page-loader/external-legal-page-loader';
import { GDPR_PAGES } from '../../utils/app.enums';

export const PolitiqueConfidentialitePage = () => {
    return (
        <div className='politique-confidentialite'>
            <div className='insert_container'>
                <ExternalLegalPageLoader legalPageName={GDPR_PAGES.POLITICAL} />
            </div>
        </div>
    );
};
