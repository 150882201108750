import cn from 'classnames';
import { useState } from 'react';

import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { FAMILY_KEYS } from '../../utils/app.enums';
import { Func } from '../../utils/app.types';
import './page-filter.scss';

export type PageFilterProps = {
    filters: Array<string>;
    onFilterChange: Func<string | undefined>;
};

export const PageFilter = ({ filters, onFilterChange }: PageFilterProps) => {
    const [selectedItem, setSelectedItem] = useState('ALL');
    const { t } = useTypedTranslation();

    const onItemSelect = (name: string) => {
        if (name === 'ALL') {
            onFilterChange(undefined);
            setSelectedItem(name);
        } else {
            onFilterChange(name);
            setSelectedItem(name);
        }
    };

    if (!filters.length) {
        return null;
    }

    return (
        <div className='page_filter'>
            {['ALL', ...filters].map((name) => (
                <div
                    key={name}
                    className={cn('filter_item', { active: selectedItem === name })}
                    onClick={() => onItemSelect(name)}>
                    {t(FAMILY_KEYS[name])}
                </div>
            ))}
        </div>
    );
};
