import i18n from 'i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enTranslation from './lang/en.json';
import frTranslation from './lang/fr.json';

const resources = {
    en: {
        translation: enTranslation,
    },
    fr: {
        translation: frTranslation,
    },
};

i18n
    //.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        fallbackLng: 'fr',
        lng: 'fr',
    });

export default i18n;
