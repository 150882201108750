import { useGetMeQuery } from '../services/base.service';
import { selectSupplierId } from '../store/selectors';
import { useTypedSelector } from '../store/store';

export const useGetUserInformation = (skip?: boolean) => {
    const id = useTypedSelector(selectSupplierId);

    const {
        data: userData,
        isLoading,
        isError,
    } = useGetMeQuery([id], {
        skip: !id || skip,
    });

    return {
        userData,
        isLoading: isLoading,
        isError,
    };
};
