/* istanbul ignore file */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SnackBarState = {
    type: 'warning' | 'error';
    content: string;
    errorDescription?: string;
    show: boolean;
};

const initialState: SnackBarState = {
    type: 'warning',
    content: '',
    errorDescription: '',
    show: false,
};

export const snackBarSlice = createSlice({
    name: 'snack-bar',
    initialState: initialState,
    reducers: {
        showSnackBar: (state, action: PayloadAction<Omit<SnackBarState, 'show'>>) => {
            return {
                ...action.payload,
                show: true,
            };
        },
        hideSnackBar: () => {
            return initialState;
        },
    },
});

export const { showSnackBar, hideSnackBar } = snackBarSlice.actions;
