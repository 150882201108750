import cn from 'classnames';
import { ChangeEvent, FocusEvent, RefObject, useState } from 'react';

import { ReactComponent as IcoEye } from '../../assets/icons/ico-eye.svg';
import { emptyFn } from '../../utils/helpers.utils';
import './input.scss';

type InputType = {
    id: string;
    label: string;
    value: string;
    name: string;
    type: 'text' | 'password';
    onChange(e: ChangeEvent<HTMLInputElement>): void;
    onBlur?(e: FocusEvent<unknown>): void;
    onFocus?(e: FocusEvent<unknown>): void;
    error?: string | boolean;
    showValidation?: boolean;
    inputRef?: RefObject<HTMLInputElement>;
    autoComplete?: 'new-password' | 'on';
};

export const Input = ({
    label,
    type,
    value,
    id,
    name,
    error = '',
    onChange,
    onBlur = emptyFn,
    onFocus = emptyFn,
    showValidation = false,
    inputRef,
    autoComplete = 'on',
}: InputType) => {
    const [isShowingPass, setIsShowingPass] = useState(false);

    const showPassword = () => setIsShowingPass((state) => !state);

    return (
        <div
            className={cn('text_input', {
                ['input--success']: !error && value,
                ['input--error']: error && showValidation,
            })}>
            <div className='error_message'>{error}</div>
            <span className='label_input_container'>
                {type === 'password' && (
                    <div
                        data-testid='eye_icon'
                        onClick={showPassword}
                        className={cn('show_pass', { active: isShowingPass })}>
                        <IcoEye />
                    </div>
                )}

                <input
                    autoComplete={autoComplete}
                    ref={inputRef}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    id={id}
                    name={name}
                    type={isShowingPass ? 'text' : type}></input>
                <label className={cn({ ['label--static']: !!value })}>{label}</label>
            </span>
        </div>
    );
};
