import React from 'react';

import { useGetAccountThemes } from '../../hooks/use-get-account-themes.hook';

type ColoredIconProps = {
    stroke?: boolean;
    fill?: boolean;
    children: React.ReactNode;
};

export const ColoredIcon = ({ stroke, fill = true, children }: ColoredIconProps) => {
    const { themes } = useGetAccountThemes();

    const fillColor = fill ? themes.secondary : 'transparent';
    const strokeColor = stroke ? themes.secondary : 'transparent';

    return <div style={{ fill: fillColor, stroke: strokeColor }}>{children}</div>;
};
