import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { Loader } from '../components/loader/loader';
import { useGetAccountsQuery } from '../services/base.service';
import { setAccount } from '../store/slice/account.slice';
import { ACCOUNT_STORE_KEY, PAGES } from '../utils/app.enums';
import { Account } from '../utils/app.types';
import { getFirstAlphabeticalAccount } from '../utils/helpers.utils';

type AuthRouteProps = {
    children: JSX.Element;
};

export const AuthRoute = ({ children }: AuthRouteProps) => {
    const { data, isLoading, isFetching } = useGetAccountsQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        if (data) {
            const savedAccountJson = localStorage.getItem(ACCOUNT_STORE_KEY);
            let account: Account;

            if (savedAccountJson) {
                account = JSON.parse(savedAccountJson);
            } else {
                account = getFirstAlphabeticalAccount(data);
            }

            dispatch(setAccount(account));
        }
    }, [data]);

    if (data) {
        return children;
    }

    if (isLoading || isFetching) {
        return (
            <div className='initial_loader'>
                <Loader isDark />
            </div>
        );
    }

    return <Navigate to={PAGES.login} />;
};
