import cn from 'classnames';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as RightIcon } from '../../../assets/icons/ico-right.svg';
import { useOutsideClick } from '../../../hooks/use-outside-click.hook';
import { useTypedTranslation } from '../../../hooks/use-typed-translation';
import { useCommunsBeneficiaryQuery, useListOfBeneficiaryQuery } from '../../../services/beneficiary.service';
import { selectBeneficiaryValidationAndSupplierId } from '../../../store/selectors';
import { setBeneficiary, setFormValidation } from '../../../store/slice/create-donation.slice';
import { useTypedSelector } from '../../../store/store';
import { Beneficiary } from '../../../utils/app.types';
import { Input } from '../../input/input';
import './beneficiary-select-dropdown.scss';

export const BeneficiarySelectDropdown = () => {
    const dispatch = useDispatch();
    const [shouldOpenList, setShouldOpenList] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const { supplierId, isBeneficiaryValid, showBeneficiaryError, beneficiary } = useTypedSelector(
        selectBeneficiaryValidationAndSupplierId
    );
    const [textToSearch, setTextToSearch] = useState('');
    const { t } = useTypedTranslation();
    const { data: beneficiaryData } = useCommunsBeneficiaryQuery(
        {
            page: 1,
            perPage: 10000,
            supplier: supplierId,
            sort: 'nameBeneficiary',
            sortOrder: 'asc',
        },
        { skip: !supplierId }
    );

    const { data: specificBeneficiaryData } = useListOfBeneficiaryQuery(
        {
            page: 1,
            perPage: 10000,
            supplier: supplierId,
            sort: 'nameBeneficiary',
            sortOrder: 'asc',
        },
        { skip: !supplierId }
    );

    const onInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
        setTextToSearch(target.value);
        setShouldOpenList(true);
    }, []);

    const beneficiaryToDisplay = useMemo(() => {
        if (specificBeneficiaryData && beneficiaryData) {
            const textToSearchL = textToSearch.toLocaleLowerCase();
            const allBeneficiary = [...specificBeneficiaryData.list, ...beneficiaryData.list].sort((a, b) =>
                a.nameBeneficiary?.toLocaleLowerCase() < b.nameBeneficiary?.toLocaleLowerCase() ? -1 : 1
            );

            if (textToSearchL === beneficiary?.nameBeneficiary.toLocaleLowerCase() || textToSearchL === '') {
                return allBeneficiary;
            }

            return allBeneficiary.filter((beneficiary) =>
                beneficiary.nameBeneficiary.toLowerCase().includes(textToSearch.toLowerCase())
            );
        }
        return [];
    }, [textToSearch, beneficiaryData, specificBeneficiaryData]);

    const onBeneficiarySelect = useCallback((item: Beneficiary) => {
        setShouldOpenList(false);
        setTextToSearch(item.nameBeneficiary);
        dispatch(setBeneficiary(item));
    }, []);

    useOutsideClick(ref, () => {
        setShouldOpenList(false);
    });

    useEffect(() => {
        if (!shouldOpenList && beneficiary) {
            setTextToSearch(beneficiary.nameBeneficiary);
        }
    }, [beneficiary, shouldOpenList]);

    const onArrowClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShouldOpenList((state) => !state);
    };

    useEffect(() => {
        if (beneficiary?.beneficiaryId && beneficiaryData) {
            const beneficiaire = beneficiaryData.list.find(
                (benef) => benef.beneficiaryId === beneficiary.beneficiaryId
            );

            if (beneficiaire) {
                onBeneficiarySelect(beneficiaire);
            }
        }
    }, [beneficiary, beneficiaryData]);

    const validateBeneficiary = () => {
        if (!beneficiary) {
            dispatch(setFormValidation({ isBeneficiaryValid: false, showBeneficiaryError: true }));
        }
    };

    useEffect(() => {
        if (!beneficiary) {
            setTextToSearch('');
        }
    }, [beneficiary]);

    return (
        <div className='beneficiary_select_dropdown'>
            <div className='selection_item'>
                <Input
                    autoComplete='new-password'
                    type='text'
                    id='benef_name'
                    name='benef_name'
                    label={t('IDS_BENEFICIAIRIES_FIELD_PLACEHOLDER_pick-beneficiairy')}
                    value={textToSearch}
                    onChange={onInputChange}
                    onFocus={() => setShouldOpenList(true)}
                    onBlur={() => validateBeneficiary()}
                    error={!isBeneficiaryValid && t('IDS_GLOBAL_ERROR_required-field')}
                    showValidation={showBeneficiaryError}
                />
                <RightIcon
                    className={cn('right_icon', {
                        up_icon: shouldOpenList,
                        error: showBeneficiaryError,
                    })}
                    onClick={onArrowClick}
                />
            </div>
            {shouldOpenList && (
                <div ref={ref} id='beneficiary_select_list' className='beneficiary_select_list'>
                    <ul>
                        {beneficiaryToDisplay?.map((item) => (
                            <li key={item.beneficiaryId} onClick={() => onBeneficiarySelect(item)}>
                                {item.nameBeneficiary}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
