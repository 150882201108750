import cn from 'classnames';
import { FC, useRef } from 'react';

import { useCheckOverflow } from '../../hooks/use-check-overflow.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { Beneficiary, Func } from '../../utils/app.types';
import { ToolTip } from '../tooltip/tooltip';
import './beneficiarie-card.scss';

export type BeneficiarieCardProps = {
    item: Beneficiary;
    type: 'editable' | 'not-editable';
    onClick: Func<Beneficiary>;
};

export const BeneficiarieCard: FC<BeneficiarieCardProps> = ({ item, type, onClick }) => {
    const { t } = useTypedTranslation();
    const titleRef = useRef<HTMLDivElement>(null);
    const withToolTip = useCheckOverflow(titleRef);

    const onCardClick = () => {
        onClick(item);
    };

    return (
        <div onClick={onCardClick} className={cn('beneficiarie_card', type)}>
            <div className='beneficiarie_card_content'>
                <ToolTip isActive={withToolTip} content={item.nameBeneficiary}>
                    <div ref={titleRef} className='title'>
                        {item.nameBeneficiary}{' '}
                    </div>
                </ToolTip>

                <div className='address_container'>
                    <div className='sub_title'>{item.firstname + ' ' + item.name}</div>
                    <div className='address'>{item.adresse}</div>
                    <div className='address'>{item.adress_bis}</div>
                    <div className='country_address'>{item.zip_code + ' ' + item.city}</div>
                </div>
            </div>

            <div className='actions'>
                {type === 'editable' ? (
                    <>
                        <div className='action_item'>{t('IDS_GLOBAL_edit')}</div>
                        <div className='action_item'>{t('IDS_GLOBAL_delete')}</div>
                    </>
                ) : (
                    <div className='action_item'>{t('IDS_GLOBAL_view')}</div>
                )}
            </div>
        </div>
    );
};
