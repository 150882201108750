import { useMemo } from 'react';

export const Copyright = () => {
    const currentYear = useMemo(() => {
        const date = new Date();
        const year = date.getFullYear();

        return year;
    }, []);

    return <div>©{currentYear} ATF Gaia</div>;
};
