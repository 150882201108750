/* istanbul ignore file */
import { Navigate, Route, Routes } from 'react-router-dom';

import { PageLayout } from '../components/page-layout/page-layout';
import { BeneficiariesPage } from '../pages/beneficiaries/beneficiaries.page';
import CookiesDeclarationPage from '../pages/cookies-declaration/cookies-declaration.page';
import { DashboardPage } from '../pages/dashboard/dashboard.page';
import { DonationsPage } from '../pages/donations/donations.page';
import { EquipmentPage } from '../pages/equipment/equipment.page';
import { LogInPage } from '../pages/login/login.page';
import { MaintainsPage } from '../pages/maintains-page/maintains-page';
import { MentionsLegalesPage } from '../pages/mentions-legales/mentions-legales.page';
import { NeedHelpMobilePage } from '../pages/need-help-mobile/need-help-mobile';
import { ParametersPage } from '../pages/parameters/parameters.page';
import { PolitiqueConfidentialitePage } from '../pages/politique-confidentialite/politique-confidentialite.page';
import { PAGES } from '../utils/app.enums';
import { AuthRoute } from './AuthRoute';

export const AppRouts = () => {
    return (
        <Routes>
            <Route path={PAGES.login} element={<LogInPage />}></Route>
            <Route path={PAGES.home} element={<Navigate to={PAGES.dashboard} />}></Route>
            <Route
                path={PAGES.dashboard}
                element={
                    <AuthRoute>
                        <PageLayout>
                            <DashboardPage />
                        </PageLayout>
                    </AuthRoute>
                }></Route>
            <Route
                path={PAGES.donations}
                element={
                    <AuthRoute>
                        <PageLayout>
                            <DonationsPage />
                        </PageLayout>
                    </AuthRoute>
                }></Route>
            <Route
                path={PAGES.equipment}
                element={
                    <AuthRoute>
                        <PageLayout>
                            <EquipmentPage />
                        </PageLayout>
                    </AuthRoute>
                }></Route>
            <Route
                path={PAGES.beneficiaries}
                element={
                    <AuthRoute>
                        <PageLayout>
                            <BeneficiariesPage />
                        </PageLayout>
                    </AuthRoute>
                }></Route>
            <Route
                path={PAGES.parameters}
                element={
                    <AuthRoute>
                        <PageLayout>
                            <ParametersPage />
                        </PageLayout>
                    </AuthRoute>
                }></Route>

            <Route
                path={PAGES.need_help_m}
                element={
                    <AuthRoute>
                        <PageLayout>
                            <NeedHelpMobilePage />
                        </PageLayout>
                    </AuthRoute>
                }></Route>
            <Route path={PAGES.maintains} element={<MaintainsPage />}></Route>
            <Route path={PAGES.mentions_legales} element={<MentionsLegalesPage />}></Route>
            <Route path={PAGES.cookies_declaration} element={<CookiesDeclarationPage />}></Route>
            <Route path={PAGES.politique_confidentialite} element={<PolitiqueConfidentialitePage />}></Route>
        </Routes>
    );
};
