import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { useGetAccountsQuery } from '../../services/base.service';
import { selectSupplierId } from '../../store/selectors';
import { setAccount } from '../../store/slice/account.slice';
import { deselectClient } from '../../store/slice/create-donation.slice';
import { useTypedSelector } from '../../store/store';
import { ACCOUNT_STORE_KEY } from '../../utils/app.enums';
import { Account } from '../../utils/app.types';
import { MultiSelect } from '../multi-select/multi-select';
import './account-select-modal-content.scss';

type AccountSelectModalContentProps = {
    onSelect(): void;
};
export const AccountSelectModalContent = ({ onSelect }: AccountSelectModalContentProps) => {
    const supplierId = useTypedSelector(selectSupplierId);
    const { data: accounts } = useGetAccountsQuery();
    const { t } = useTypedTranslation();
    const dispatch = useDispatch();

    const onAccountSelect = useCallback((account: Account) => {
        dispatch(setAccount(account));
        dispatch(deselectClient());
        localStorage.setItem(ACCOUNT_STORE_KEY, JSON.stringify(account));
        onSelect();
    }, []);

    const mapAccountsToSelectList = (subAccounts: Array<Account>) => {
        return subAccounts.map((account) => ({
            value: account,
            isSelected: account.id === supplierId,
            label: account.name,
        }));
    };

    return (
        <div className='account_select_modal'>
            <div className='content_container'>
                <div className='account_select_title'>{t('IDS_ACCOUNT_MODAL_TITLE_choose-supplier')}</div>
                {accounts?.map(({ name, subAccounts }) => (
                    <div key={name} className='account_group'>
                        <h4>{name}</h4>
                        <MultiSelect
                            type='radio-btn'
                            items={mapAccountsToSelectList(subAccounts)}
                            onChange={onAccountSelect}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
