import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { baseApi } from '../services/base.service';
import { authGuardMiddleware } from '../services/rtk-middleware';
import { accountSlice } from './slice/account.slice';
import { createDonationSlice } from './slice/create-donation.slice';
import { snackBarSlice } from './slice/snack-bar.slice';

export const globalReducer = combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    snackBar: snackBarSlice.reducer,
    account: accountSlice.reducer,
    createDonation: createDonationSlice.reducer,
});

export const store = configureStore({
    reducer: globalReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(baseApi.middleware).concat(authGuardMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
