import cn from 'classnames';
import { FC } from 'react';

import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { DONATION_STATUSES_TRANSLATION_KEYS } from '../../utils/app.enums';
import { DonationStatusType } from '../../utils/app.types';
import './donation-status-label.scss';

type DonationStatusLabelProps = {
    status?: DonationStatusType;
};

export const DonationStatusLabel: FC<DonationStatusLabelProps> = ({ status }) => {
    const { t } = useTypedTranslation();

    if (!status) {
        return null;
    }

    return <span className={cn('status', status)}>{t(DONATION_STATUSES_TRANSLATION_KEYS[status])}</span>;
};
