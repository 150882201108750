import cn from 'classnames';
import { FC } from 'react';

import { Func } from '../../utils/app.types';
import './counter-btn.scss';

type CounterButtonProps = {
    onChange: Func<number>;
    maxVal: number;
    value: number;
    className?: string;
    isReadOnly?: boolean;
};
export const CounterButton: FC<CounterButtonProps> = ({
    onChange,
    maxVal,
    value = 0,
    className = 'default',
    isReadOnly = true,
}) => {
    const changeCount = (direction: 'left' | 'right') => () => {
        if (!isReadOnly) {
            if (direction === 'left' && value > 0) {
                onChange(value - 1);
            } else if (direction === 'right' && value < maxVal) {
                onChange(value + 1);
            }
        }
    };

    const shouldShowError = value > maxVal && !isReadOnly;
    return (
        <div
            className={cn('counter_button', className, { error: shouldShowError, not_editable: isReadOnly })}>
            <div className='action left' onClick={changeCount('left')}>
                <div className='counter_item'>-</div>
            </div>

            <input
                readOnly={isReadOnly}
                value={value}
                max={maxVal}
                min={0}
                onBlur={({ target: { value } }) => value === '' && onChange(0)}
                onChange={({ target: { value } }) => onChange(Number.parseInt(value))}
                type={'number'}
            />

            <div className='action right' onClick={changeCount('right')}>
                {value === maxVal ? (
                    <div className='max_item'>MAX</div>
                ) : (
                    <div className='counter_item'>+</div>
                )}
            </div>
        </div>
    );
};
