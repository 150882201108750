import { ReactComponent as Logo } from '../../assets/icons/atf-logo.svg';
import { Button } from '../../components/button/button';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import './maintains-page.scss';

export const MaintainsPage = () => {
    const { t } = useTypedTranslation();
    return (
        <div className='maintains_page'>
            <div className='header'></div>
            <div className='logo'>
                <Logo />
            </div>
            <div className='message'>{t('IDS_MAINTENANCE_TITLE_maintenance')}</div>
            <div className='sub_message'>{t('IDS_MAINTENANCE_DESCRIPTION_come-back-later')}</div>
            <Button type='primary' className='reload_btn' onClick={() => window.location.reload()}>
                {t('IDS_GLOBAL_refresh')}
            </Button>
        </div>
    );
};
