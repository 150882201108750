import { FC } from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/ico-close.svg';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { Beneficiary, Func } from '../../utils/app.types';
import './beneficiary-side-bar.scss';

type BeneficiarySideBarProps = {
    item?: Beneficiary;
    onClose: Func<unknown>;
};
export const BeneficiarySideBarContent: FC<BeneficiarySideBarProps> = ({ item, onClose }) => {
    const { t } = useTypedTranslation();

    return (
        <div className='beneficiary_side_bar'>
            <div className='close_icon'>
                <div className='icon_container' onClick={onClose}>
                    <CloseIcon />
                </div>
            </div>

            <div className='beneficiary_side_bar_content'>
                <div className='title'>{item?.nameBeneficiary}</div>
                <div className='info'>
                    <div className='sub_title'>
                        {t('IDS_BENEFICIAIRIES_DETAILS_TITLE_charity-info')}
                    </div>
                    <div className='information'>
                        <span>{item?.nameBeneficiary}</span>
                        <span>{`${item?.firstname} ${item?.name}`}</span>
                        <span>{item?.mail}</span>
                        <span>{item?.phone}</span>
                    </div>
                </div>
                <div className='info'>
                    <div className='sub_title'>{t('IDS_GLOBAL_address')}</div>
                    <div className='information'>
                        <span>{item?.nameBeneficiary}</span>
                        <span>{item?.adresse}</span>
                        <span>{item?.adress_bis}</span>
                        <span>{`${item?.zip_code} ${item?.city}`}</span>
                        <span>{item?.country}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
