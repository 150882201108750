import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { ReactComponent as AtfLogo } from '../../assets/icons/atf-logo.svg';
import { ReactComponent as BeneficiaireIcon } from '../../assets/icons/ico-beneficiaire.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/ico-close.svg';
import { ReactComponent as DonateIcon } from '../../assets/icons/ico-donate.svg';
import { ReactComponent as EquipmentIcon } from '../../assets/icons/ico-equipment.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/ico-home.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/ico-logout.svg';
//import { ReactComponent as SettingsIcon } from '../../assets/icons/ico-settings.svg';
import { useGetAccountThemes } from '../../hooks/use-get-account-themes.hook';
import { useLogout } from '../../hooks/use-logout.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { ATF_GAIA_URL, PAGES } from '../../utils/app.enums';
import { emptyFn } from '../../utils/helpers.utils';
import { Base64Image } from '../base64-image/base64-image';
import { ColoredIcon } from '../colored-icon/colored-icon';
import { ExternalLink } from '../link/external-link';
import { NeedHelp } from '../need-help/need-help';
import { AccountSelect } from './account-select-button/account-select-button';
import './navigation-menu.scss';

type NavigationMenuProps = {
    className?: string;
    onMenuClick?: () => void;
    isMobile?: boolean;
    mobileOnCloseMenu?: () => void;
};
export const NavigationMenu = ({
    className = 'default',
    onMenuClick,
    isMobile = false,
    mobileOnCloseMenu = emptyFn,
}: NavigationMenuProps) => {
    const { t } = useTypedTranslation();
    const { themes, isLoading } = useGetAccountThemes();
    const logOut = useLogout();

    return (
        <aside className={cn('navigation_menu', className)}>
            {isMobile && (
                <div className='mobile_content'>
                    <div className='mobile_header'>
                        <NeedHelp />

                        <div className='icon_placeholder' onClick={mobileOnCloseMenu}>
                            <CloseIcon />
                        </div>
                    </div>
                </div>
            )}

            <div className='logo'>
                <NavLink to={PAGES.dashboard}>
                    <Base64Image isLoading={isLoading} base64Str={themes.logo} />
                </NavLink>
            </div>
            <div className='company_select_container'>
                <AccountSelect />
            </div>
            <nav className='navigation_items_container' onClick={onMenuClick}>
                <NavLink className={({ isActive }) => cn({ active: isActive })} to={PAGES.dashboard}>
                    <div className='icon_container'>
                        <ColoredIcon>
                            <HomeIcon />
                        </ColoredIcon>
                        <span>{t('IDS_MENU_dashboard')}</span>
                    </div>
                </NavLink>
                <NavLink className={({ isActive }) => cn({ active: isActive })} to={PAGES.donations}>
                    <div className='icon_container'>
                        <ColoredIcon>
                            <DonateIcon />
                        </ColoredIcon>

                        <span>{t('IDS_MENU_donations')}</span>
                    </div>
                </NavLink>

                <NavLink className={({ isActive }) => cn({ active: isActive })} to={PAGES.equipment}>
                    <div className='icon_container'>
                        <ColoredIcon>
                            <EquipmentIcon />
                        </ColoredIcon>

                        <span>{t('IDS_MENU_equipments')}</span>
                    </div>
                </NavLink>
                <NavLink className={({ isActive }) => cn({ active: isActive })} to={PAGES.beneficiaries}>
                    <div className='icon_container'>
                        <ColoredIcon>
                            <BeneficiaireIcon />
                        </ColoredIcon>

                        <span>{t('IDS_MENU_beneficiairies')}</span>
                    </div>
                </NavLink>
                {/* <NavLink
                    className={({ isActive }) => cn({ active: isActive })}
                    to={PAGES.parameters}>
                    <div className='icon_container'>
                        <ColoredIcon>
                            <SettingsIcon />
                        </ColoredIcon>

                        <span>{t('IDS_MENU_settings')}</span>
                    </div>
                </NavLink> */}
                <div className='logout_container'>
                    <a className={'log_out'} onClick={logOut}>
                        <div className='icon_container'>
                            <LogoutIcon />
                            <span>{t('IDS_GLOBAL_log-out')}</span>
                        </div>
                    </a>
                </div>
            </nav>
            <div className='atf_logo'>
                <ExternalLink tab href={ATF_GAIA_URL}>
                    <div className='pow_by'>{`${t('IDS_GLOBAL_powered-by')}:`}</div>
                    <AtfLogo />
                </ExternalLink>
            </div>
        </aside>
    );
};
