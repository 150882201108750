import cn from 'classnames';

import { ReactComponent as LoaderIcon } from '../../assets/icons/ico-loading.svg';
import './loader.scss';

type LoaderProps = {
    isDark?: boolean;
    isAbsolute?: boolean;
    className?: string;
};

export const Loader = ({
    isDark = false,
    isAbsolute = false,
    className = 'default',
}: LoaderProps) => (
    <div className={cn('app_loader', className, { is_dark: isDark, is_absolute: isAbsolute })}>
        <LoaderIcon />
    </div>
);
