import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import { useDeviceScreenDetect } from '../../hooks/use-device-screen-detect.hook';
import { PAGES } from '../../utils/app.enums';
import { LegalLinks } from '../legal-links/legal-links';
import { NeedHelp } from '../need-help/need-help';
import './page-layout.scss';

type PageLayoutProps = {
    children: JSX.Element;
};
export const PageLayout = ({ children }: PageLayoutProps) => {
    const { isMobile, isPortraitTablet } = useDeviceScreenDetect();
    const { pathname } = useLocation();
    const shouldShowHelpLink = !(isMobile || isPortraitTablet);

    return (
        <div className='page_layout_container'>
            {shouldShowHelpLink && (
                <div
                    className={cn('help_me_btn', { dashboard_help: pathname === PAGES.dashboard })}>
                    <NeedHelp />
                </div>
            )}

            <div className='page_content'>{children}</div>
            <div className='legal_links_containers'>
                <LegalLinks className='legal_links_page' />
            </div>
        </div>
    );
};
