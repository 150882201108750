import { useRef } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/ico-down.svg';
import { useCheckOverflow } from '../../hooks/use-check-overflow.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { DONATION_STATUSES } from '../../utils/app.enums';
import { DonationProduct } from '../../utils/app.types';
import { DonationStatusLabel } from '../donation-status-label/donation-status-label';
import { ToolTip } from '../tooltip/tooltip';
import './donation-card.scss';

export type DonationCardProps = {
    item: DonationProduct;

    onCardSelect: (donate: DonationProduct) => void;
};

export const DonationCard = ({ onCardSelect, item }: DonationCardProps) => {
    const { t } = useTypedTranslation();
    const titleRef = useRef<HTMLDivElement>(null);
    const withToolTip = useCheckOverflow(titleRef);
    const isDraft = item.status === DONATION_STATUSES.draft;
    const isWaitingSigning = item.status === DONATION_STATUSES.waiting_signature;

    return (
        <div className='donation_card' onClick={() => onCardSelect(item)}>
            <DonationStatusLabel status={item.status} />
            <div className='additional_data'>
                <div className='ref'>{item.donationID}</div>
                {isWaitingSigning && (
                    <div className='check_email'>{t('IDS_DONATION_STATUS_DETAILS_check-your-emails')}</div>
                )}
            </div>
            <ToolTip isActive={withToolTip} content={item.donationName}>
                <div ref={titleRef} className='title'>
                    {item.donationName}
                </div>
            </ToolTip>
            <div className='additional_data'>
                <div className='date'>{new Date(item.creationDate).toLocaleDateString()}</div>
            </div>
            <div className='sub_title'>
                {t('IDS_DONATION_DETAILS_selected-equipment', { count: item.items })}
            </div>
            <div className='action'>
                {isDraft ? (
                    <>
                        <div>{t('IDS_DONATION_DETAILS_BUTTON_edit-donation')}</div>
                        <ArrowIcon />
                    </>
                ) : (
                    <>
                        <div>{t('IDS_DONATION_DETAILS_BUTTON_see-donation')}</div>
                        <ArrowIcon />
                    </>
                )}
            </div>
        </div>
    );
};
