import { useCallback, useEffect, useState } from 'react';

import { BeneficiarieCard } from '../../components/beneficiarie-card/beneficiarie-card';
import { BeneficiarySideBarContent } from '../../components/beneficiary-side-bar/beneficiary-side-bar';
import { GridList } from '../../components/grid-list/grid-list';
import { Pagination } from '../../components/pagination/pagination';
import { SideBar } from '../../components/side-bar/side-bar';
import { useDeviceScreenDetect } from '../../hooks/use-device-screen-detect.hook';
import { usePaginationHook } from '../../hooks/use-pagination.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { useCommunsBeneficiaryQuery, useListOfBeneficiaryQuery } from '../../services/beneficiary.service';
import { selectSupplierId } from '../../store/selectors';
import { useTypedSelector } from '../../store/store';
import { Beneficiary } from '../../utils/app.types';
import './beneficiaries.page.scss';
import { CreateBeneficiaryLink } from './create-beneficiary-link';

export const BeneficiariesPage = () => {
    const [selectedBeneficiary, setSelectedBeneficiary] = useState<Beneficiary>();
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const paginationCommuns = usePaginationHook({ perPage: 12 });
    const paginationSpecific = usePaginationHook({ perPage: 12 });
    const { t } = useTypedTranslation();
    const id = useTypedSelector(selectSupplierId);
    const { isMobile, isPortraitTablet } = useDeviceScreenDetect();
    const showInfinityPagination = isMobile || isPortraitTablet;

    const { data: communsBeneficiaryData, isFetching: isFetchingCommuns } = useCommunsBeneficiaryQuery(
        {
            page: paginationCommuns.currentPage,
            perPage: paginationCommuns.perOnePage,
            supplier: id,
            sort: 'nameBeneficiary',
            sortOrder: 'asc',
        },
        { skip: !id }
    );

    const { data: specificBeneficiaryData, isFetching: isFetchingSpecific } = useListOfBeneficiaryQuery(
        {
            page: paginationSpecific.currentPage,
            perPage: paginationSpecific.perOnePage,
            supplier: id,
            sort: 'nameBeneficiary',
            sortOrder: 'asc',
        },
        { skip: !id }
    );

    const nextPageCommuns = useCallback(
        () =>
            paginationCommuns.setCurrentPage((page) => {
                if (paginationCommuns.lastPage === paginationCommuns.currentPage) {
                    return page;
                }
                return page + 1;
            }),
        [paginationCommuns]
    );

    const nextPageSpecific = useCallback(
        () =>
            paginationSpecific.setCurrentPage((page) => {
                if (paginationSpecific.lastPage === paginationCommuns.currentPage) {
                    return page;
                }
                return page + 1;
            }),
        [paginationSpecific]
    );

    useEffect(() => {
        paginationCommuns.setLastPage(communsBeneficiaryData?.lastPage ?? 1);
    }, [communsBeneficiaryData?.lastPage]);

    useEffect(() => {
        paginationCommuns.setLastPage(communsBeneficiaryData?.lastPage ?? 1);
    }, [paginationSpecific?.lastPage]);

    const unSelectBeneficiary = useCallback(() => setIsSideBarOpen(false), []);
    const onBeneficiarySelect = useCallback((item: Beneficiary) => {
        setSelectedBeneficiary(item);
        setIsSideBarOpen(true);
    }, []);

    const shouldShowEmptyContent =
        !isFetchingCommuns &&
        communsBeneficiaryData &&
        communsBeneficiaryData.total === 0 &&
        !isFetchingSpecific &&
        specificBeneficiaryData &&
        specificBeneficiaryData.total === 0;

    return (
        <div className='beneficiaries_page'>
            <SideBar covered open={isSideBarOpen} onOutsideClick={unSelectBeneficiary}>
                <BeneficiarySideBarContent onClose={unSelectBeneficiary} item={selectedBeneficiary} />
            </SideBar>
            <div className='beneficiaries_title_container'>
                <div className='page_title'>{t('IDS_MENU_beneficiairies')}</div>
                <CreateBeneficiaryLink />
            </div>

            {shouldShowEmptyContent && (
                <div className='no_content_description'>
                    {t('IDS_BENEFICIAIRIES_DESCRIPTION_empty-state')}
                </div>
            )}

            <div className='cards_container'>
                <div className='list_separator'>{t('IDS_BENEFICIAIRIES_our-list')}</div>
                <GridList<Beneficiary>
                    className='beneficiary_grid_list'
                    items={communsBeneficiaryData?.list ?? []}
                    pending={isFetchingCommuns}
                    infinityMode={showInfinityPagination}
                    itemPositionToObserve={-5}
                    onObservedItemInVew={nextPageCommuns}
                    render={(item) => (
                        <BeneficiarieCard
                            onClick={onBeneficiarySelect}
                            type='not-editable'
                            key={item.beneficiaryId}
                            item={item}
                        />
                    )}></GridList>
            </div>

            {!showInfinityPagination && (
                <Pagination
                    className='b_pagination'
                    {...paginationCommuns}
                    totalPages={specificBeneficiaryData?.lastPage}
                />
            )}

            <div className='cards_container'>
                {!!specificBeneficiaryData?.list.length && (
                    <div className='list_separator'>{t('IDS_BENEFICIAIRIES_my-list')}</div>
                )}
                <GridList<Beneficiary>
                    className='beneficiary_grid_list'
                    items={specificBeneficiaryData?.list ?? []}
                    pending={isFetchingSpecific}
                    infinityMode={showInfinityPagination}
                    itemPositionToObserve={-5}
                    onObservedItemInVew={nextPageSpecific}
                    render={(item) => (
                        <BeneficiarieCard
                            onClick={onBeneficiarySelect}
                            type='not-editable'
                            key={item.beneficiaryId}
                            item={item}
                        />
                    )}></GridList>
            </div>

            {!showInfinityPagination && (
                <Pagination
                    className='b_pagination'
                    {...paginationSpecific}
                    totalPages={specificBeneficiaryData?.lastPage}
                />
            )}

            {/* {isMobile && (
                <Button className='create_ben_btn' type='primary' onClick={emptyFn}>
                    {t('IDS_BENEFICIAIRIES_CTA_add-a-beneficiairy')}
                </Button>
            )} */}
        </div>
    );
};
