import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
    useLazyCommunsBeneficiaryQuery,
    useLazyListOfBeneficiaryQuery,
} from '../../services/beneficiary.service';
import { useGetDonationQuery, useLazyGetSupplierClientQuery } from '../../services/donation.service';
import { selectSupplierId } from '../../store/selectors';
import {
    resetForm,
    setBeneficiary,
    setClient,
    setDate,
    setDonationId,
    setDonationName,
    setEquipments,
    setIsUpdateForm,
    setOption,
    setStatus,
} from '../../store/slice/create-donation.slice';
import { useTypedSelector } from '../../store/store';
import { DONATION_STATUSES } from '../../utils/app.enums';
import { Func } from '../../utils/app.types';
import { DonationOverviewSideBar } from './overview-sidebar/donation-overview-sidebar';
import { UpdateDonationSideBar } from './update-donation-sidebar';

type EditViewDonationSideBarProps = {
    donationId?: number;
    donationStatus?: string;
    onClose: Func;
    shouldOpen: boolean;
    onAnimationEnd: Func;
};
export const EditViewDonationSideBar: FC<EditViewDonationSideBarProps> = ({
    donationId,
    donationStatus,
    onClose,
    shouldOpen,
    onAnimationEnd,
}) => {
    const isDraft = donationStatus === DONATION_STATUSES.draft;
    const supplierId = useTypedSelector(selectSupplierId);
    const dispatch = useDispatch();
    const { data: donation, isFetching: isDonationLoading } = useGetDonationQuery(
        {
            donationId: donationId ?? 0,
            supplier: supplierId,
        },
        { skip: !donationId }
    );

    const [getBeneficiaryList, { isFetching: isBeneficiaryLoading, data: beneficiaryData }] =
        useLazyCommunsBeneficiaryQuery();
    const [getUserBeneficiaryList, { isFetching: isUserBeneficiaryLoading, data: userBeneficiaryData }] =
        useLazyListOfBeneficiaryQuery();
    const [getClientsList, { isFetching: isClientsFetching, data: clientsData }] =
        useLazyGetSupplierClientQuery();

    useEffect(() => {
        const prepareDonations = async () => {
            if (donation) {
                const mappedProducts =
                    donation.products.map((product) => ({
                        description: product.description,
                        family: product.family,
                        grade: product.grade,
                        id: product.productId,
                        maximumCount: product.max_qty,
                        name: product.description,
                        selectedCount: product.qty,
                    })) ?? [];

                if (donation.donationId) {
                    dispatch(setDonationId(donation.donationId));
                }

                dispatch(setDonationName(donation.donationName));
                dispatch(setStatus(donation.status));
                dispatch(setDate(donation.date));
                dispatch(setEquipments(mappedProducts));
                dispatch(setOption(donation.options ?? []));
                dispatch(setIsUpdateForm(true));
                const { data: commonBeneficiary } = await getBeneficiaryList({
                    page: 1,
                    perPage: 1000,
                    supplier: supplierId,
                });

                const { data: userBeneficiary } = await getUserBeneficiaryList({
                    page: 1,
                    perPage: 100,
                    supplier: supplierId,
                    sort: 'nameBeneficiary',
                    sortOrder: 'asc',
                });

                if (commonBeneficiary && userBeneficiary) {
                    const beneficiaryToSelect = [...commonBeneficiary.list, ...userBeneficiary.list].find(
                        (item) => item.beneficiaryId === donation.beneficiaryId
                    );

                    if (beneficiaryToSelect) {
                        dispatch(setBeneficiary(beneficiaryToSelect));
                    }
                }

                const clientsList = await getClientsList(supplierId);

                const selectedClient = clientsList.data?.find((item) => item.name === donation.customerName);

                if (selectedClient) {
                    dispatch(setClient(selectedClient));
                }
            }
        };

        prepareDonations();
    }, [donation, donationId]);

    const showSideBarLoader =
        isBeneficiaryLoading ||
        isUserBeneficiaryLoading ||
        !beneficiaryData ||
        !userBeneficiaryData ||
        isDonationLoading ||
        !donation ||
        isClientsFetching ||
        !clientsData;

    useEffect(() => {
        return () => {
            dispatch(resetForm());
        };
    }, []);

    return (
        <>
            <UpdateDonationSideBar
                isOpen={shouldOpen && isDraft}
                onClose={onClose}
                beneficiaryId={donation?.beneficiaryId}
                savedOptions={donation?.options}
                onAnimationEnd={onAnimationEnd}
                isLoading={showSideBarLoader}
            />

            <DonationOverviewSideBar
                open={shouldOpen && !isDraft}
                onOutsideClick={onClose}
                donation={donation}
                onAnimationEnd={onAnimationEnd}
                isLoading={showSideBarLoader}
            />
        </>
    );
};
