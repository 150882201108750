import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import './tooltip.scss';

type ToolTipProps = {
    children: React.ReactNode;
    content: React.ReactNode | string;
    isActive?: boolean;
};

type ToolTipComponentProps = {
    parent: React.RefObject<HTMLDivElement>;
    content: React.ReactNode | string;
};

type Position = {
    top: number;
    left: number;
};

const ToolTipComponent: FC<ToolTipComponentProps> = ({ parent, content }) => {
    const toolTipRef = useRef<HTMLDivElement | null>(null);
    const toolTipArrowRef = useRef<HTMLDivElement | null>(null);
    const [toolTipPosition, setToolTipPosition] = useState<Position>();
    const [toolTipArrowPosition, setToolTipArrowPosition] = useState<Position>();

    useLayoutEffect(() => {
        const toolTipDiv = toolTipRef.current;
        const toolTipArrowDiv = toolTipArrowRef.current;
        const wrapperDiv = parent.current;

        if (toolTipDiv && toolTipArrowDiv && wrapperDiv) {
            const {
                top: wrapperTop,
                left: wrapperLeft,
                width: wrapperWidth,
            } = wrapperDiv.getBoundingClientRect();
            const { width: toolTipWidth, height: toolTipHeight } = toolTipDiv.getBoundingClientRect();

            if (window.innerWidth < toolTipWidth + wrapperLeft) {
                setToolTipPosition({
                    top: wrapperTop - toolTipHeight / 2 - 15,
                    left: wrapperLeft - (toolTipWidth + wrapperLeft - window.innerWidth) - 15,
                });
            } else {
                setToolTipPosition({
                    top: wrapperTop - toolTipHeight / 2 - 15,
                    left: wrapperLeft,
                });
            }

            setToolTipArrowPosition({
                top: wrapperTop - 15,
                left: wrapperLeft + wrapperWidth / 2,
            });
        }
    }, []);

    return (
        <div>
            <div ref={toolTipRef} className='tooltip' id='app-tooltip' style={{ ...toolTipPosition }}>
                {content}
            </div>
            <div ref={toolTipArrowRef} style={{ ...toolTipArrowPosition }} className='tooltip_arrow' />
        </div>
    );
};

export const ToolTip: FC<ToolTipProps> = ({ children, content, isActive = true }) => {
    const toolTipWarperRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const showToolTip = () => isActive && setOpen(true);
    const hideToolTip = () => setOpen(false);

    useEffect(() => {
        window.addEventListener('scroll', hideToolTip);
        window.addEventListener('touchmove', hideToolTip);
        window.addEventListener('wheel', hideToolTip);
        window.addEventListener('mousewheel', hideToolTip);
        window.addEventListener('touchstart', hideToolTip);
        return () => {
            window.removeEventListener('scroll', hideToolTip);
            window.removeEventListener('touchmove', hideToolTip);
            window.removeEventListener('wheel', hideToolTip);
            window.removeEventListener('touchstart', hideToolTip);
            window.removeEventListener('mousewheel', hideToolTip);
        };
    }, []);

    return (
        <div
            ref={toolTipWarperRef}
            className='app_tooltip_wrapper'
            onClick={showToolTip}
            onMouseEnter={showToolTip}
            onMouseLeave={hideToolTip}>
            {open &&
                ReactDOM.createPortal(
                    <ToolTipComponent parent={toolTipWarperRef} content={content} />,
                    document.body as HTMLElement
                )}
            {children}
        </div>
    );
};
