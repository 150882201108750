import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as DonationIcon } from '../../assets/icons/ico-donate.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/ico-information.svg';
import { Button } from '../../components/button/button';
import { UpdateDonationSideBar } from '../../components/donation-sidebars/update-donation-sidebar';
import { GridList } from '../../components/grid-list/grid-list';
import { PageFilter } from '../../components/page-filter/page-filter';
import { Pagination } from '../../components/pagination/pagination';
import { ProductCard } from '../../components/product-card/product-card';
import { ToolTip } from '../../components/tooltip/tooltip';
import { useDeviceScreenDetect } from '../../hooks/use-device-screen-detect.hook';
import { usePaginationHook } from '../../hooks/use-pagination.hook';
import { TypedTranslationFn, useTypedTranslation } from '../../hooks/use-typed-translation';
import { useGetEquipmentsQuery } from '../../services/donation.service';
import { selectSupplierIdAndIsEquipmentsSelected } from '../../store/selectors';
import { resetForm } from '../../store/slice/create-donation.slice';
import { useTypedSelector } from '../../store/store';
import { EquipmentProduct } from '../../utils/app.types';
import { getPageCount } from '../../utils/helpers.utils';
import './equipment.page.scss';

const CreateDonationBtnTooltip = ({ t }: { t: TypedTranslationFn }) => (
    <div className='create_donation_tooltip_with_icon'>
        <div className='info_icon'>
            <InfoIcon />
        </div>
        {t('IDS_EQUIPMENT_ERROR_add-equipments')}
    </div>
);

const renderEquipmentCard = (item: EquipmentProduct) => <ProductCard key={item.productId} item={item} />;

export const EquipmentPage = () => {
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const [filterVal, setFilterVal] = useState<string>();
    const dispatch = useDispatch();
    const { t } = useTypedTranslation();
    const { supplierId, isEquipmentSelected } = useTypedSelector(selectSupplierIdAndIsEquipmentsSelected);
    const pagination = usePaginationHook({ perPage: 12 });
    const { isMobile, isPortraitTablet } = useDeviceScreenDetect();
    const { data: equipmentsResponse, isFetching } = useGetEquipmentsQuery(
        {
            page: pagination.currentPage,
            perPage: 12,
            supplier: supplierId,
            filter: filterVal,
        },
        { skip: !supplierId }
    );
    const onDonationCreateClick = () => {
        setIsSideBarOpen(true);
    };

    const shouldShowEquipmentState = !isFetching && equipmentsResponse && equipmentsResponse.count === 0;
    const shouldShowDesktopCreateDonationBtn =
        !isMobile && !isPortraitTablet && !!equipmentsResponse?.list?.length;
    const shouldShowMobile = (isMobile || isPortraitTablet) && !!equipmentsResponse?.list?.length;
    useEffect(() => {
        return () => {
            dispatch(resetForm());
        };
    }, []);

    const onFilterChange = useCallback((val: string | undefined) => {
        pagination.setCurrentPage(1);
        setFilterVal(val);
    }, []);

    const CreateDonationBtn = () => (
        <div className='create_donation_button_container'>
            <ToolTip isActive={!isEquipmentSelected} content={<CreateDonationBtnTooltip t={t} />}>
                <Button
                    isDisabled={!isEquipmentSelected}
                    className='create_donation'
                    type='primary'
                    onClick={onDonationCreateClick}>
                    <span>{t('IDS_EQUIPMENT_CTA_create-donation-with-selected-equipment')}</span>
                    <div className='icon'>
                        <DonationIcon />
                    </div>
                </Button>
            </ToolTip>
        </div>
    );

    const totalPages = getPageCount(equipmentsResponse?.count, pagination.perOnePage);
    return (
        <div className='equipment_page'>
            <UpdateDonationSideBar isOpen={isSideBarOpen} onClose={() => setIsSideBarOpen(false)} />
            <div className='page_title'>
                {t('IDS_EQUIPMENT_COUNT_available-equipment_other', {
                    count: equipmentsResponse?.totalEquipement ?? 0,
                })}
            </div>
            <div className='filter'>
                <PageFilter filters={equipmentsResponse?.Family ?? []} onFilterChange={onFilterChange} />
            </div>

            {shouldShowEquipmentState && (
                <div className='no_content_description'>{t('IDS_EQUIPMENT_DESCRIPTION_empty-state')}</div>
            )}
            <div className='content'>
                <GridList<EquipmentProduct>
                    className='equipment_page_grid'
                    items={equipmentsResponse?.list ?? []}
                    pending={isFetching}
                    render={renderEquipmentCard}
                />
            </div>
            {shouldShowMobile && <CreateDonationBtn />}
            <div className={cn('page_navigation', { single: totalPages <= 1 })}>
                <Pagination className='p_pagination' {...pagination} totalPages={totalPages} />
                {shouldShowDesktopCreateDonationBtn && <CreateDonationBtn />}
            </div>
        </div>
    );
};
