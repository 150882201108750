import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ContactIcon } from '../../assets/icons/ico-contact.svg';
import { useDeviceScreenDetect } from '../../hooks/use-device-screen-detect.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { PAGES } from '../../utils/app.enums';
import { AppModal } from '../app-modal/app-modal';
import { NeedHelpModalContent } from './need-help-modal-content';
import './need-help.scss';

export const NeedHelp = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTypedTranslation();
    const navigate = useNavigate();
    const { isMobile } = useDeviceScreenDetect();

    const onHelpClick = () => {
        if (isMobile) {
            navigate(PAGES.need_help_m);
        } else {
            setIsModalOpen(true);
        }
    };

    return (
        <div className={'need_help'}>
            <AppModal
                id='need_help_modal'
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}>
                <NeedHelpModalContent />
            </AppModal>

            <div className={'need_help_content'} onClick={onHelpClick}>
                <ContactIcon />
                <div className={'contact_link'}>{t('IDS_GLOBAL_need-help')}</div>
            </div>
        </div>
    );
};
