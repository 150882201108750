import { AnimatePresence } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DonationCard } from '../../components/donation-card/donation-card';
import { EditViewDonationSideBar } from '../../components/donation-sidebars/edit-view-sidebar';
import { GridList } from '../../components/grid-list/grid-list';
import { Pagination } from '../../components/pagination/pagination';
import { usePaginationHook } from '../../hooks/use-pagination.hook';
import { useSupplierId } from '../../hooks/use-selectors.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { useGetDonationListQuery } from '../../services/donation.service';
import { resetForm } from '../../store/slice/create-donation.slice';
import { DonationProduct } from '../../utils/app.types';
import { getPageCount } from '../../utils/helpers.utils';
import './donation.page.scss';

export const DonationsPage = () => {
    const { t } = useTypedTranslation();
    const { supplierId } = useSupplierId();
    const pagination = usePaginationHook({ perPage: 12 });
    const { data: donationData, isFetching } = useGetDonationListQuery({
        page: pagination.currentPage,
        perPage: pagination.perOnePage,
        supplier: supplierId,
    });
    const [selectedDonation, setSelectedDonation] = useState<DonationProduct>();
    const dispatch = useDispatch();
    const [shouldOpenSidebar, setShouldOpenSidebar] = useState(false);

    const onDonationSelect = useCallback((donation: DonationProduct) => {
        setSelectedDonation(donation);
        setShouldOpenSidebar(true);
    }, []);

    const onSideBarClose = () => {
        setShouldOpenSidebar(false);
        setSelectedDonation(undefined);
    };

    const deselectDonation = useCallback(() => {
        dispatch(resetForm());
        setSelectedDonation(undefined);
    }, []);

    const renderDonationCard = useCallback(
        (item: DonationProduct) => (
            <DonationCard onCardSelect={onDonationSelect} key={item.donationID} item={item} />
        ),
        []
    );

    const shouldShowEmptyContent = !isFetching && donationData && donationData.total === 0;

    useEffect(() => {
        return () => {
            dispatch(resetForm());
        };
    }, []);

    return (
        <div className='donation_page'>
            <AnimatePresence>
                {selectedDonation && (
                    <EditViewDonationSideBar
                        onClose={onSideBarClose}
                        donationId={selectedDonation?.donationID}
                        donationStatus={selectedDonation?.status}
                        shouldOpen={shouldOpenSidebar}
                        onAnimationEnd={deselectDonation}
                    />
                )}
            </AnimatePresence>

            <div className='page_title'>{t('IDS_MENU_donations')}</div>
            {shouldShowEmptyContent && (
                <div className='no_content_description'>{t('IDS_DONATION_DESCRIPTION_empty-state')}</div>
            )}
            <GridList<DonationProduct>
                className='donation_grid_list'
                items={donationData?.data ?? []}
                pending={isFetching}
                render={renderDonationCard}></GridList>

            <Pagination
                className='donation_list_pagination'
                {...pagination}
                totalPages={getPageCount(donationData?.total, donationData?.perPage)}
            />
        </div>
    );
};
