import React, { FC } from 'react';
import { ExternalLegalPageLoader } from '../../components/external-legal-page-loader/external-legal-page-loader';
import { GDPR_PAGES } from '../../utils/app.enums';

export const MentionsLegalesPage: FC = () => {
    return (
        <div className='mentions-legales'>
            <div className='insert_container'>
                <ExternalLegalPageLoader legalPageName={GDPR_PAGES.MENTIONS} />
            </div>
        </div>
    );
};
