import { createSelector } from '@reduxjs/toolkit';

import { SelectedEquipment } from '../utils/app.types';
import { RootState } from './store';

export const selectCreateDonationState = (state: RootState) => state.createDonation;
export const selectEquipments = (state: RootState) => state.createDonation.products;
export const selectSupplierId = (state: RootState) => state.account.selectedAccount.id;
export const selectSupplierName = (state: RootState) => state.account.selectedAccount.name;
export const selectCreateDonationFormValidationState = (state: RootState) => state.createDonation.validation;

export const selectEquipmentById = createSelector(
    selectEquipments,
    (_: RootState, id: string) => id,
    (equipments, id) => equipments[id]
);

export const selectEquipmentsArray = createSelector(selectEquipments, (equipmentsMap) =>
    Object.values(equipmentsMap)
);

export const selectEquipmentsArraySideBar = createSelector(selectCreateDonationState, ({ products }) =>
    Object.values(products)
);

export const selectEquipmentsArrayAndSupplierId = createSelector(
    selectEquipmentsArray,
    selectSupplierId,
    (equipments, supplierId) => ({ equipments, supplierId: supplierId })
);

export const selectSupplierIdAndIsEquipmentsSelected = createSelector(
    selectEquipmentsArrayAndSupplierId,
    ({ equipments, supplierId }) => ({ supplierId, isEquipmentSelected: equipments.length !== 0 })
);

export const selectCreateDonationForm = createSelector(
    selectCreateDonationState,
    selectSupplierId,
    selectEquipmentsArraySideBar,
    (
        { donationName, options, beneficiary, isUpdate, donationId, status, date, client },
        supplierId,
        products
    ) => ({
        donationName,
        options,
        products,
        beneficiary,
        supplierId: supplierId,
        isUpdateMode: isUpdate,
        donationId,
        status,
        date,
        client,
    })
);

export const selectDonationNameValidation = createSelector(
    selectCreateDonationFormValidationState,
    (donationFormState) => ({
        isNameValid: donationFormState?.isNameValid,
        showNameError: donationFormState?.showNameError,
    })
);
export const selectBeneficiaryValidationAndSupplierId = createSelector(
    selectCreateDonationFormValidationState,
    selectSupplierId,
    selectCreateDonationForm,
    (donationFormState, supplierId, form) => ({
        isBeneficiaryValid: donationFormState?.isBeneficiaryValid,
        beneficiary: form.beneficiary,
        showBeneficiaryError: donationFormState?.showBeneficiaryError,
        supplierId,
    })
);

export const selectClientValidationAndSupplierId = createSelector(
    selectCreateDonationFormValidationState,
    selectSupplierId,
    selectCreateDonationForm,
    (donationFormState, supplierId, form) => ({
        isClientValid: donationFormState?.isClientValid,
        client: form.client,
        showClientValidError: donationFormState?.showClientValidError,
        supplierId,
    })
);

const selectIsEquipmentsValid = (products: SelectedEquipment[]) => {
    return (
        products.length !== 0 &&
        products.every(
            (product) => product.selectedCount > 0 && product.selectedCount <= product.maximumCount
        )
    );
};

export const selectProductCount = createSelector(selectEquipmentsArraySideBar, (products) =>
    products.reduce((com, product) => com + product.selectedCount, 0)
);

export const selectIsDonationValidForCreation = createSelector(
    selectCreateDonationFormValidationState,
    selectCreateDonationForm,
    (donationFormState, form) => {
        return !!(
            donationFormState?.isNameValid &&
            donationFormState?.isBeneficiaryValid &&
            donationFormState?.isClientValid &&
            selectIsEquipmentsValid(form.products)
        );
    }
);
