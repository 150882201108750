import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DonationIcon } from '../../assets/icons/ico-donate.svg';
import { Base64Image } from '../../components/base64-image/base64-image';
import { Button } from '../../components/button/button';
import { DonationCard } from '../../components/donation-card/donation-card';
import { EditViewDonationSideBar } from '../../components/donation-sidebars/edit-view-sidebar';
import { GridList } from '../../components/grid-list/grid-list';
import { useDeviceScreenDetect } from '../../hooks/use-device-screen-detect.hook';
import { useGetAccountThemes } from '../../hooks/use-get-account-themes.hook';
import { useTypedTranslation } from '../../hooks/use-typed-translation';
import { useGetDonationListQuery } from '../../services/donation.service';
import { selectSupplierId } from '../../store/selectors';
import { useTypedSelector } from '../../store/store';
import { DONATION_STATUSES, PAGES } from '../../utils/app.enums';
import { DonationProduct } from '../../utils/app.types';
import './dashboard.scss';

export const DashboardPage = () => {
    const id = useTypedSelector(selectSupplierId);
    const [selectedItem, setSelectedItem] = useState<DonationProduct>();
    const [showSideBar, setShowSideBar] = useState(false);
    const { themes, isLoading } = useGetAccountThemes();
    const { t } = useTypedTranslation();
    const { isMobile } = useDeviceScreenDetect();
    const navigate = useNavigate();

    const { data: donationListResponse, isFetching: isListFetching } = useGetDonationListQuery(
        {
            supplier: id,
            page: 0,
            filter: { status: DONATION_STATUSES.draft },
            perPage: 100,
        },
        { skip: !id }
    );

    const onCardClick = useCallback((donate: DonationProduct) => {
        setSelectedItem(donate);
        setShowSideBar(true);
    }, []);

    const closeSideBar = useCallback(() => {
        setShowSideBar(false);
        setSelectedItem(undefined);
    }, []);

    const onSideBarCloseAnimationEnd = useCallback(() => {
        setSelectedItem(undefined);
    }, []);

    const toEquipments = () => navigate(PAGES.equipment);

    const CreateBtn = () => (
        <div className={cn('create_donation_btn', { mobile: isMobile })}>
            <Button className='create_button' type='primary' onClick={toEquipments}>
                <div>{t('IDS_DONATION_BUTTON_create-donation')}</div> <DonationIcon />
            </Button>
        </div>
    );

    return (
        <div className={'dashboard_page'}>
            <AnimatePresence>
                {selectedItem && (
                    <EditViewDonationSideBar
                        onAnimationEnd={onSideBarCloseAnimationEnd}
                        shouldOpen={showSideBar}
                        onClose={closeSideBar}
                        donationId={selectedItem?.donationID}
                        donationStatus={selectedItem?.status}
                    />
                )}
            </AnimatePresence>

            <div className='main_picture'>
                <Base64Image base64Str={themes.banner} isLoading={isLoading} />
                {!isLoading && <div className='page_name'>{t('IDS_MENU_dashboard')}</div>}
            </div>
            {!isMobile && <CreateBtn />}
            <div className='donation_list_title'>{t('IDS_DONATION_TITLE_empty-state')}</div>

            <div className='card_container'>
                {!donationListResponse?.data.length && !isListFetching && (
                    <div className='no_content'>
                        <div className='no_content_description'>
                            {t('IDS_DASHBOARD_DESCRIPTION_empty-state')}
                        </div>
                    </div>
                )}
                <GridList<DonationProduct>
                    items={donationListResponse?.data ?? []}
                    className='donation_product_list'
                    pending={isListFetching}
                    render={(item) => (
                        <DonationCard key={item.donationID} item={item} onCardSelect={onCardClick} />
                    )}
                />
            </div>

            {isMobile && <CreateBtn />}
        </div>
    );
};
