import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
    Beneficiary,
    Client,
    DonationOption,
    DonationStatusType,
    FiledProduct,
    SelectedEquipment,
} from '../../utils/app.types';

type ChangeQuantityPayload = {
    id: string;
    quantity: number;
};
type CreateDonationState = {
    options: DonationOption[];
    products: Record<string, SelectedEquipment>;
    donationName: string;
    donationId?: number;
    beneficiary?: Beneficiary;
    isFormValid?: boolean;
    validation: ValidationState;
    isUpdate?: boolean;
    status?: DonationStatusType;
    date?: string;
    client?: Client;
};

type ValidationState = {
    isNameValid?: boolean;
    isBeneficiaryValid?: boolean;
    isClientValid?: boolean;
    showNameError?: boolean;
    showBeneficiaryError?: boolean;
    showClientValidError?: boolean;
};
const initialState: CreateDonationState = {
    options: [],
    products: {},
    donationName: '',
    validation: {
        showBeneficiaryError: false,
        showNameError: false,
        showClientValidError: false,
    },
    isUpdate: false,
};
export const createDonationSlice = createSlice({
    name: 'CreateDonation',
    initialState,
    reducers: {
        setEquipment: (state, { payload }: PayloadAction<SelectedEquipment>) => {
            const { id } = payload;
            state.products[id] = payload;
        },
        setEquipments: (state, { payload }: PayloadAction<SelectedEquipment[]>) => {
            const initVal: Record<string, SelectedEquipment> = {};
            state.products = payload.reduce((init, current) => {
                init[current.id] = current;

                if (init[current.id].maximumCount < init[current.id].selectedCount) {
                    init[current.id].stockError = true;
                }
                return init;
            }, initVal);
        },
        deselectEquipment: (state, { payload: id }: PayloadAction<string>) => {
            const { [id]: _, ...withoutElement } = state.products;
            state.products = withoutElement;
        },
        changeSelectedItemQuantity: (
            state,
            { payload: { id, quantity } }: PayloadAction<ChangeQuantityPayload>
        ) => {
            const equipmentIndexToEdit = state.products[id];
            if (equipmentIndexToEdit) {
                equipmentIndexToEdit.selectedCount = quantity;

                if (quantity <= equipmentIndexToEdit.maximumCount) {
                    equipmentIndexToEdit.stockError = false;
                }
            }
        },
        updateStockErrorsAndQuantity: (state, { payload: products }: PayloadAction<FiledProduct[]>) => {
            products.forEach(({ productId, maxqty }) => {
                const equipmentIndexToEdit = state.products[productId];
                if (equipmentIndexToEdit) {
                    equipmentIndexToEdit.stockError = true;
                    equipmentIndexToEdit.maximumCount = maxqty;
                }
            });
        },

        showAllStockErrors: (state) => {
            Object.values(state.products).forEach((product) => {
                product.stockError = true;
                product.maximumCount = 0;
            });
        },
        resetStockErrorState: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
            const equipmentIndexToEdit = state.products[id];
            if (equipmentIndexToEdit) {
                equipmentIndexToEdit.stockError = false;
            }
        },
        deselectAllEquipments: (state) => {
            state.products = initialState.products;
        },
        setDonationId: (state, { payload }: PayloadAction<number>) => {
            state.donationId = payload;
        },
        setClient: (state, { payload }: PayloadAction<Client>) => {
            state.client = payload;

            if (payload) {
                state.validation = {
                    ...state.validation,
                    isClientValid: true,
                };
            } else {
                state.validation = { ...state.validation, isClientValid: false };
            }
        },
        deselectClient: (state) => {
            state.client = undefined;
            state.validation.isClientValid = false;
        },
        setDonationName: (state, { payload }: PayloadAction<string>) => {
            state.donationName = payload;
            if (payload) {
                state.validation = {
                    ...state.validation,
                    isNameValid: true,
                };
            } else {
                state.validation = { ...state.validation, isNameValid: false };
            }
        },
        setBeneficiary: (state, { payload }: PayloadAction<Beneficiary>) => {
            state.beneficiary = payload;

            if (payload) {
                state.validation = {
                    ...state.validation,
                    isBeneficiaryValid: true,
                    showBeneficiaryError: false,
                };
            } else {
                state.validation = { ...state.validation, isBeneficiaryValid: false };
            }
        },
        setOption: (state, { payload }: PayloadAction<DonationOption[]>) => {
            state.options = payload;
        },
        setStatus: (state, { payload }: PayloadAction<DonationStatusType>) => {
            state.status = payload;
        },
        setDate: (state, { payload }: PayloadAction<string>) => {
            state.date = payload;
        },
        setFormValidation: (state, { payload }: PayloadAction<ValidationState>) => {
            state.validation = { ...state.validation, ...payload };
        },
        setIsUpdateForm: (state, { payload }: PayloadAction<boolean>) => {
            state.isUpdate = payload;
        },
        resetForm: () => {
            return initialState;
        },
    },
});

export const {
    setEquipment,
    deselectEquipment,
    changeSelectedItemQuantity,
    updateStockErrorsAndQuantity,
    resetStockErrorState,
    deselectAllEquipments,
    setEquipments,
    setDonationName,
    setBeneficiary,
    setOption,
    setFormValidation,
    setIsUpdateForm,
    setDonationId,
    resetForm,
    setStatus,
    setDate,
    setClient,
    deselectClient,
    showAllStockErrors,
} = createDonationSlice.actions;
